import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "./ReduxStore";
import { UserInfoResponse } from "../api/backend/applicationApi";
import { Status } from "../api/backend/admin/customerApi";

export const roleValues = ['DTADMIN', 'DTSERVICEAGENT', 'DTREADER', 'CUSTOMERADMIN', 'CUSTOMERUSER'] as const;
export type Role = typeof roleValues[number];

export interface UserInfo {
    role?: Role;
    customerNr?: string;
    status?: Status;
}

const userInfoInitialState: UserInfo = {
    role: undefined,
    customerNr: undefined
};

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: userInfoInitialState,
    reducers: {
        setUserInfo: (_state, action: PayloadAction<UserInfoResponse & {
            status?: Status
        }>) => ({
            role: action.payload.role,
            customerNr: action.payload.customer?.number,
            status: action.payload.status
        }),
        clearUserInfo: () => ( userInfoInitialState )
    }
});

export const isFromDtSelector = createSelector(
    (rootState: RootState) => rootState.userInfoSliceReducer,
    (userInfoState) => {
        const { role } = userInfoState;
        if (role) {
            return isDtRole(userInfoState.role)
        }
    }
)

export const isDisabledSelector = createSelector(
    [(rootState: RootState) => rootState.userInfoSliceReducer, isFromDtSelector],
    (userInfoState, isFromDt) => !isFromDt && !(userInfoState.status === 'ACTIVE')
)

export const isDtRole = (role?: Role) => role === 'DTADMIN' || role === 'DTSERVICEAGENT' || role === 'DTREADER'

export const { setUserInfo, clearUserInfo } = userInfoSlice.actions;
export const userInfoSliceReducer = userInfoSlice.reducer;