import { ScaleDropdownSelect, ScaleDropdownSelectItem } from "@telekom/scale-components-react"
import { roleValues } from "../../../store/UserInfoSlice"
import { ProductDTO } from "../../../store/workflow/models"
import { RoleBookingConfiguration } from "../../../api/backend/admin/configApi";
import { ScaleDropdownSelectCustomEvent } from "@telekom/scale-components";
import { AdminFormChild, AdminFormChildParams } from "./common/AdminForm";

const RoleConfigurationForm = (productList: ProductDTO[]): AdminFormChild<RoleBookingConfiguration> =>
    function _({formValues, handleChange, renderTextField, isCreateMode}: AdminFormChildParams<RoleBookingConfiguration>)
        {
            return (
                    <>
                        <ScaleDropdownSelect
                            label="Role*"
                            onScale-change={(event: ScaleDropdownSelectCustomEvent<unknown>) => handleChange(event, "role")}
                            value={formValues.role}
                            data-testid="formDropdownRole"
                            disabled={!isCreateMode}
                        >
                            {
                                roleValues.map(role =>
                                    <ScaleDropdownSelectItem key={role} value={role}>
                                        {role}
                                    </ScaleDropdownSelectItem>)
                            }
                        </ScaleDropdownSelect>
                        <ScaleDropdownSelect
                            label="Produkt*"
                            onScale-change={(event: ScaleDropdownSelectCustomEvent<unknown>) => handleChange(event, "productOfferingId")}
                            value={formValues.productOfferingId}
                            data-testid="formDropdownProduct"
                            disabled={!isCreateMode}
                        >
                        {
                            productList.map(product =>
                                <ScaleDropdownSelectItem key={product.id} value={product.id}>
                                    {product.name}
                                </ScaleDropdownSelectItem>)
                        }
                        </ScaleDropdownSelect>
                        { renderTextField("min. leadtime in minutes*", "minBookingLeadTime") }
                        { renderTextField("max. leadtime in days*", "maxBookingLeadTime") }
                        { renderTextField("cancellation deadling in minutes*", "cancellationDeadline") }
                        { renderTextField("max booking period in hours*", "bookingPeriod") }
                    </>
            )
        }

export default RoleConfigurationForm