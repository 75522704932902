import classes from "./Impressum.module.css"

const ImpressumPage = () => {
    return (
        <div className={`row ${classes.impressumContainer}`}>
            <div className="col"></div>
            <div className="col">
                <div className={`${classes.article}`}>
                    <div className={`${classes.header}`}>
                        <h1>Impressum</h1>
                    </div>
                </div>
                
                <div className={`${classes.article}`}>
                    <h2>Kontakt</h2>

                    <p>
                        <br/>
                        Wir beraten Sie gern!
                        <br/>
                        Kostenlos, 24 Stunden am Tag, 7 Tage die Woche
                        <br/>
                        Nutzen Sie den <a href="https://www.telekom.de/kontakt" target="_self" className="link">direkten Kontakt</a> zu uns!
                    </p>
                </div>

                <div className={`${classes.article}`}>
                    <h2>Adresse</h2>

                    <p>
                        <br/>
                        Telekom Deutschland GmbH
                        <br/>
                        Landgrabenweg 151
                        <br/>
                        53227 Bonn
                        <br/>
                        Telefon: 0228 - 181 0
                        <br/>
                        Persönlicher Kontakt zu unserem Kundenservice: <a href="https://www.telekom.de/kontakt/e-mail-kontakt" target="_self" className="link">E-Mail an den Kundenservice</a> zu uns!
                    </p>
                </div>

                <div className={`${classes.article}`}>
                    <h2>Handelsregister</h2>

                    <p>
                        <br/>
                        Amtsgericht Bonn HRB 5919
                        <br/>
                        Sitz der Gesellschaft Bonn
                        <br/>
                        USt-IdNr. DE 122265872
                        <br/>
                        WEEE-Reg.-Nr. DE60800328
                        <br/>
                        E-Mail: <a href="MAILTO:impressum@telekom.de" target="_self" className="link">impressum@telekom.de</a>
                    </p>
                </div>

                <div className={`${classes.article}`}>
                    <h2>Vertretungsberechtigt (geschäftsführend)</h2>

                    <p>
                        <br/>
                        <strong>Srinivasan Gopalan</strong>, Sprecher der Geschäftsführung
                        <br/>
                        <strong>Dr. Kerstin Baumgart</strong>, Geschäftsführerin Wholesale
                        <br/>
                        <strong>Dr. Robert Hauber</strong>, Geschäftsführer Finanzen
                        <br/>
                        <strong>Wolfgang Metze</strong>, Geschäftsführer Privatkunden
                        <br/>
                        <strong>Dr. Abdurazak Mudesir</strong>, Geschäftsführer Technologie
                        <br/>
                        <strong>Steffen Schlaberg</strong>, Geschäftsführer Vertrieb & Service
                        <br/>
                        <strong>Simone Thiäner</strong>, Geschäftsführerin Personal (Arbeitsdirektorin)
                        <br/>
                        <strong>Klaus Werner</strong>, Geschäftsführer Geschäftskunden
                    </p>
                </div>

                <div className={`${classes.article}`}>
                    <h2>Aufsichtsbehörde</h2>

                    <p>
                        <br/>
                        Bundesnetzagentur für Elektrizität, Gas,
                        <br/>
                        Telekommunikation, Post und Eisenbahnen
                        <br/>
                        Tulpenfeld 4
                        <br/>
                        53113 Bonn
                    </p>
                </div>

                <div className={`${classes.article}`}>
                    <h2>Inhaltlich verantwortlich</h2>

                    <p>
                        <br/>
                        <strong>Christina Kiehl</strong>, Senior Vice President Customer Development & Digital Success
                        <br/>
                        Landgrabenweg 151
                        <br/>
                        53227 Bonn
                    </p>
                </div>

                <div className={`${classes.article}`}>
                    <h2>Haftungsausschluss</h2>
                    <br/>

                    <p>Die Telekom Deutschland GmbH übernimmt keine Garantie dafür, dass die auf dieser Website bereitgestellten Informationen vollständig, richtig und in jedem Fall aktuell sind. Insbesondere übernimmt die Telekom Deutschland GmbH keine Haftung für Inhalte, die ausdrücklich oder konkludent als fremde Inhalte gekennzeichnet sind. Die Telekom Deutschland GmbH ist nicht dafür verantwortlich, dass solche Inhalte vollständig, richtig, aktuell und rechtmäßig sind und nicht in unzulässiger Weise in Rechtsgüter Dritter eingreifen. Dies gilt auch für Inhalte von Webseiten, auf die durch einen Link verwiesen wird.</p>

                    <p>Die Telekom Deutschland GmbH behält sich das Recht vor, ohne vorherige Ankündigung Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen oder diese zu entfernen.</p>

                    <p>Auf keinen Fall haftet die Telekom Deutschland GmbH für Schäden, die durch fehlende Nutzungsmöglichkeiten oder Datenverluste im Zusammenhang mit der Nutzung von Dokumenten oder Informationen bzw. der Erbringung von Dienstleistungen entstehen, die auf dieser Website zugänglich sind.</p>
                </div>

                <div className={`${classes.article}`}>
                    <h2>Außergerichtliche Streitbeilegung</h2>
                    <br/>
                    <br/>

                    <p><strong>a) Informationen zur Verbraucherstreitbeilegung nach § 36 Verbraucherstreitbeilegungsgesetz (VSBG)</strong></p>

                    <p><strong>Verbraucherschlichtungsstelle Telekommunikation der Bundesnetzagentur in Bonn</strong></p>

                    <p>Zur Beilegung eines Streits mit der Telekom über die in § 68 TKG genannten Fälle kann der Kunde bei der Verbraucherschlichtungsstelle Telekommunikation der Bundesnetzagentur in Bonn (Verbraucherschlichtungsstelle) durch einen Antrag ein Schlichtungsverfahren einleiten. Die Telekom ist bereit, an Schlichtungsverfahren von der Bundesnetzagentur teilzunehmen.</p>

                    <p>
                        Die Kontaktdaten der Verbraucherschlichtungsstelle lauten:
                        <br/>
                        Bundesnetzagentur
                        <br/>
                        Verbraucherschlichtungsstelle Telekommunikation (Referat 216)
                        <br/>
                        Postfach 80 01
                        <br/>
                        53105 Bonn
                        <br/>
                        <a href="https://www.bundesnetzagentur.de/" target="_blank" rel="noreferrer" className="link">www.bundesnetzagentur.de</a>
                        <br/>
                    </p>

                    <p><strong>Sonstige Streitfälle</strong></p>

                    <p>Im Übrigen nimmt die Telekom nicht an Streitbeilegungsverfahren vor einer anderen Verbraucherschlichtungsstelle teil. Der Telekom ist vielmehr daran gelegen, Streitigkeiten mit ihren Kunden im direkten Kontakt zu klären. Bitte wenden Sie sich an den Kundenservice der Telekom.</p>

                    <br/>

                    <p><strong>b) Informationen zur Online-Streitbeilegung nach Artikel 14 Abs. 1 ODR-VO</strong></p>

                    <p>
                        Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) verbraucherrechtlicher Streitigkeiten bereit, die aus Online-Kaufverträgen und Online-Dienstleistungsverträgen resultieren. Diese Plattform erreichen Sie im Internet unter:
                        <br/>
                        <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer" className="link">https://ec.europa.eu/consumers/odr/</a>
                    </p>
                </div>
            </div>
            <div className="col"></div>
        </div>
    )
}

export default ImpressumPage