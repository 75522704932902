import {
  ScaleButton,
  ScaleModal,
  ScaleTextField,
} from "@telekom/scale-components-react";
import { PropsReactChildren } from "../../../common/model";
import { useEffect, useState } from "react";
import { ZodFirstPartyTypeKind, ZodType, ZodTypeAny } from "zod";
import { RestOperations } from "./list/AdminList";
import { ScaleTextFieldCustomEvent } from "@telekom/scale-components";
import { InputChangeEventDetail } from "@telekom/scale-components/dist/types/components/input/input";
import classes from "./AdminForm.module.css";
import { useAdminListContext } from "./list/AdminListContext";
import { RestError } from "@/api/api";

export type AdminFormChildParams<T> = {
  formValues: Partial<T>;
  handleChange: (
    event: { target?: { value?: unknown } },
    name: keyof T
  ) => void;
  renderTextField: (
    label: string,
    name: keyof T,
    isDisabled?: boolean
  ) => JSX.Element;
  isCreateMode: boolean;
};

export type AdminFormChild<T> = (
  params: AdminFormChildParams<T>
) => PropsReactChildren;

export type ZodTypeWithShape<T> = ZodType & {
  get shape(): FormZodType<T>;
};
type FormZodType<T> = {
  [k in keyof T]: ZodTypeAny;
};
export const initForm: Partial<unknown> = {};
const successfullPostResponseCodes = [200, 201];

export const AdminForm = <FORM,>({
  zodType,
  restOperations,
  formName,
  children,
}: {
  zodType: ZodTypeWithShape<FORM>;
  restOperations: Omit<RestOperations<FORM, unknown>, "deleteElement">;
  formName: string;
  children: AdminFormChild<FORM>;
}) => {
  const [formValues, setFormValues] = useState<Partial<FORM>>(initForm);
  //workaround flag which allows to set state in ScaleTextField component
  const [isExperimentalControlled, setIsExperimentalControlled] =
    useState<boolean>(false);
  const { selected, setSelected, reloadTable } = useAdminListContext();
  const selectedForm = selected?.form;

  const isOpened = () => selectedForm !== undefined;

  const isCreateMode = () => selected?.isCreateMode ?? false;

  useEffect(() => {
    setFormValuesAndSynchronizeScaleComponents(selectedForm ?? {});
  }, [selectedForm]);

  const fetchAndReturnHttpCode = async (form: FORM): Promise<number> => {
    try {
      if (isCreateMode()) {
        return await restOperations.createElement(form);
      } else {
        return await restOperations.modifyElement(form);
      }
    } catch (e) {
      console.error(`Modify Error: ${JSON.stringify(e)}`);
      const restError = e as RestError;
      return restError.status;
    }
  };

  const submitHandler = async (form: FORM): Promise<boolean> => {
    const isSuccess = successfullPostResponseCodes.includes(
      await fetchAndReturnHttpCode(form)
    );
    if (isSuccess) {
      await reloadTable();
    }

    return isSuccess;
  };

  const handleChange = (
    event: { target?: { value?: unknown } },
    name: keyof FORM
  ) => {
    if (event.target) {
      setFormValues({
        ...formValues,
        [name]: getValue(event.target.value, name),
      });
    }
  };

  const getValue = (value: unknown, name: keyof FORM) => {
    const type = getZodTypeByFieldName(name, zodType.shape);
    try {
      if (type === ZodFirstPartyTypeKind.ZodNumber) {
        const retValue = Number(value);

        if (isNaN(retValue)) {
          throw new Error();
        }
        if (value === "") {
          return undefined;
        }

        return retValue;
      }
    } catch (e) {
      console.log("invalid value put in form");
    }

    return value;
  };

  const getZodTypeByFieldName = (
    name: keyof FORM,
    val: FormZodType<FORM>
  ): ZodFirstPartyTypeKind => {
    const zodType = val[name]._def["typeName"];
    if (zodType === undefined) {
      return ZodFirstPartyTypeKind.ZodAny;
    }
    return zodType;
  };

  const handleClose = () => {
    setSelected(undefined);
    setFormValuesAndSynchronizeScaleComponents(initForm);
  };

  const handleSubmit = async () => {
    if (!isValid(formValues)) {
      alert("Form is invalid");
      return;
    }

    if (!(await submitHandler(formValues))) {
      console.error("Error while submitting form");
    }

    handleClose();
  };

  const setFormValuesAndSynchronizeScaleComponents = (
    formState: Partial<FORM>
  ) => {
    setIsExperimentalControlled(true);
    setFormValues(formState);
    setIsExperimentalControlled(false);
  };

  const isValid = (obj: Partial<FORM>): obj is FORM => {
    try {
      zodType.parse(obj);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getFormValue = (name: keyof FORM) => {
    const value = formValues[name];
    if (value === undefined) {
      return undefined;
    }
    return String(value);
  };

  const renderTextField = (
    label: string,
    name: keyof FORM,
    isDisabled?: boolean
  ) => (
    <ScaleTextField
      label={label}
      onScale-change={(
        event: ScaleTextFieldCustomEvent<InputChangeEventDetail>
      ) => handleChange(event, name)}
      experimentalControlled={isExperimentalControlled}
      disabled={isDisabled !== undefined ? isDisabled : false}
      value={getFormValue(name)}
    />
  );

  const getTitle = () =>
    isCreateMode() ? `neue ${formName} hinzufügen` : `${formName} editieren`;

  return (
    <ScaleModal
      opened={isOpened()}
      onScale-close={handleClose}
      omitCloseButton={true}
      heading={getTitle()}
      size="small"
      data-testid="adminModalForm"
    >
      <div className={classes.adminForm}>
        {children({
          formValues,
          handleChange,
          renderTextField,
          isCreateMode: isCreateMode(),
        })}

        <div className={classes.buttons}>
          <ScaleButton slot="action" onClick={handleSubmit}>
            {isCreateMode() ? "Hinzufügen" : "Speichern"}
          </ScaleButton>
          <ScaleButton slot="action" variant="secondary" onClick={handleClose}>
            Abbrechen
          </ScaleButton>
        </div>
      </div>
    </ScaleModal>
  );
};
