import CustomerListComponent from '../../components/sections/admin/CustomerListComponent';
import { ApplicationState } from '../../store/ApplicationSlice';

const CustomerPage = ({applicationState}: { applicationState: ApplicationState }) => {
    return (
        <>
            <CustomerListComponent applicationState={applicationState} />
        </>
    )
}

export default CustomerPage