import Header from "../header/Header";
import Footer from "../Footer";
import { PropsReactChildren } from "../../common/model";
import { ApplicationState } from "../../../store/ApplicationSlice";
import { ToastContainer } from "react-toastify";

const MainBody = ({
  children,
  applicationState,
}: {
  children: PropsReactChildren;
  applicationState: ApplicationState;
}) => {
  return (
    <>
      <Header applicationState={applicationState} />
      <div className={`container-fluid ps-0 pe-0 bread mainContainer`}>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          {children}
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default MainBody;
