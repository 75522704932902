import { PropsReactChildren } from "../../../../common/model"
import { AdminForm, AdminFormChild } from "../AdminForm"
import DeleteConfirmationModal from "../DeleteConfirmationModal"
import classes from './AdminList.module.css';
import { FormTable, EmptyTable, ShowEditControlsFunction, Predicate } from "./FormTable"
import { useAdminListContext } from "./AdminListContext";
import { PaginationRequest, PaginationResponse } from "../../../../../api/api";
import { useAppSelector } from "../../../../../store/ReduxStore";

export type AdminListRow<T> = (row: T) => PropsReactChildren;
export type FormState<T> = {
    form: T,
    isCreateMode: boolean
}
export type RestOperations<T, ID> = {
    getList: (paginationRequest: PaginationRequest<T>) => Promise<PaginationResponse<T[]>>,
    createElement: (form: T) => Promise<number>,
    modifyElement: (form: T) => Promise<number>,
    deleteElement: (formToDelete: ID) => Promise<number>,
}
export type TableHeader<FORM> = {
    name: string,
    value: keyof FORM
}
export type AdminComponentContent<FORM,ID> = {
    tableName: string,
    formName: string,
    tableHeaders: TableHeader<FORM>[],
    getIdentifier?: (id: ID) => string
}

export type VisibilityConfiguration<FORM> = {
    isVisible: boolean,
    isShowEditControls: ShowEditControlsFunction,
    deleteButtonDisabled?: Predicate<FORM>;
}

export type AdminListParams<FORM, ID> = {
    componentContent: AdminComponentContent<FORM, ID>
    restOperations: RestOperations<FORM, ID>,
    visibilityConfiguration: VisibilityConfiguration<FORM>,
    children: AdminListRow<FORM>,
    childForm: AdminFormChild<FORM>
}

export const AdminList = <FORM,ID = Partial<FORM>>({componentContent, restOperations, visibilityConfiguration, children, childForm}: AdminListParams<FORM, ID>) => {
        const { totalElements, zodType } = useAdminListContext<FORM, ID>()
        const { role } = useAppSelector((state) => state.userInfoSliceReducer);
        const showEditControls = visibilityConfiguration.isShowEditControls(role)

        return (
            visibilityConfiguration.isVisible ?
                <>
                    <div className={classes.adminList}>
                        <h1 className='teleNeo42Bold'>{componentContent.tableName}</h1>
                        {
                            totalElements > 0 ?
                                <FormTable componentContent={componentContent} editControls={{
                                    isVisible: showEditControls,
                                    deleteButtonDisabled: visibilityConfiguration.deleteButtonDisabled
                                }}>
                                    {children}
                                </FormTable>
                                : 
                                <EmptyTable componentContent={componentContent} showEditControls={showEditControls}/>
                        }
                    </div>
                    <AdminForm restOperations={restOperations} zodType={zodType} formName={componentContent.formName}>
                        { childForm }
                    </AdminForm>

                    <DeleteConfirmationModal componentContent={componentContent}
                        deleteHandler={restOperations.deleteElement} />
                </>
            : null
        )
    }