import LoginPage from "./pages/login/LoginPage";
import {RootState, useAppSelector} from "./store/ReduxStore";
import MainBody from "./components/ui/layout/MainBody";
import { useAuth } from "react-oidc-context";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { isFromDtSelector } from "./store/UserInfoSlice";
import ImpressumPage from "./pages/static/Impressum";
import BookingArea from "./pages/booking/BookingArea";
import AdminArea from "./pages/admin/AdminArea";

function App() {
    const auth = useAuth();
    const applicationState = useAppSelector((state) => state.applicationState.applicationState);
    const isFromDt = useSelector<RootState, boolean | undefined>(isFromDtSelector);

    return <Router>
              <MainBody applicationState={applicationState}>
            
                <Routes>
                    <Route path="/" element=
                        {auth.isAuthenticated ?
                            <>
                                <BookingArea applicationState={applicationState} />
                                <AdminArea applicationState={applicationState} />
                            </>
                            :
                            <LoginPage />
                        } />
                    
                    <Route path="/datenschutz" element={<RedirectToPrivacy isFromDt={isFromDt}/>} />
                    <Route path="/impressum" element={<ImpressumPage />} />
                </Routes>
            </MainBody>
        </Router>
}

export const getPrivacyUrl = (isFromDt?: boolean) =>
    isFromDt !== undefined ? 
        isFromDt ? "https://datenschutzhinweise.telekom.de/default.aspx?q=14a1c515-7e1b-4ff1-9633-d6c86f4ba16e"
            : "https://www.telekom.de/datenschutzhinweise/download/388.pdf"
    :   "https://www.telekom.de/datenschutzhinweise"

function RedirectToPrivacy({isFromDt}: {isFromDt?: boolean}) {
    window.location.replace(getPrivacyUrl(isFromDt));
    return null;
}

export default App;
