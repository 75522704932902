import { DateOrder, SimCardDTO } from '../../../store/workflow/models';
import TileTemplate from '../../ui/common/TileTemplate'
import {formatDate, formatMinutes} from '../../utils/OrderDetailsFormatter';
import classes from "./OrderSummaryTile.module.css";

const OrderSummaryTile = ({orderDate, simCard} : {orderDate: DateOrder | undefined, simCard: SimCardDTO | undefined}) => {
  return (
    <TileTemplate className={`${classes.container}`}>
      {
        orderDate !== undefined &&
        <>
          <OrderSummaryLine title='Anfangsdatum' value={`${formatDate(orderDate.dateFrom)}, ${orderDate.hourFrom} : ${formatMinutes(orderDate.minuteFrom ?? 0)}`}/>
          <OrderSummaryLine title='Enddatum' value={`${formatDate(orderDate.dateTo)}, ${orderDate.hourTo} : ${formatMinutes(orderDate.minuteTo ?? 0)}`} />
        </>
      }
      {
        simCard?.msisdn !== undefined &&
          <OrderSummaryLine title='SIM-Karte' value={`MSISDN: ${simCard.msisdn}`} />
      }
    </TileTemplate>
  )
}


const OrderSummaryLine = ({title, value} : {title: string, value: string}) => {
    return (
        <div className={`${classes.orderSummaryLine}`}>
            <div className='teleNeo16 gray'>
                {title}
            </div>
            <div className={`teleNeo16Bold ${classes.value}`}>
                {value}
            </div>
        </div>
    )
}

export default OrderSummaryTile