import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { resetWorkflow } from '../CommonActions';
import {SimCardDTO, CustomerDTO} from "./models"
import { RootState } from '../ReduxStore';
import { getSimCards } from '../../api/backend/simCardsApi';
import { getSelectedProductSelector } from './ProductSlice';

interface SimCardContext {
    simCardsMap: SimCardMapEntry[];
    simCardRelatedPartiesList: CustomerDTO[];
    selectedSimMsisdn?: string;
    selectedRelatedPartyId?: string;
}

type SimCardMapEntry = {
    relatedParty: string;
    simCards: SimCardDTO[]
}

const initialState:SimCardContext = {
    simCardsMap: [],
    simCardRelatedPartiesList: [],
    selectedSimMsisdn: undefined,
    selectedRelatedPartyId: undefined,
}

export const setRelatedParty = createAsyncThunk<void, string, {state: RootState}>('simCards/setRelatedParty',
  async (relatedPartyId, { dispatch, getState }) => {
  dispatch(setRelatedPartyId(relatedPartyId));
  dispatch(setSimCardMsisdn(undefined));
  if (findSimCards(getState().simCardsReducer.simCardsMap, relatedPartyId) === undefined) {
    try {
      const selectedProduct = getSelectedProductSelector(getState());
      const simCards = await getSimCards(relatedPartyId, selectedProduct?.id);
      dispatch(pushSimCardList({relatedParty: relatedPartyId, simCards: simCards}))
    } catch (error) {
      console.error("Error in reading simcards");
    }
  }
})

export const initSimcards = createAsyncThunk<void, void, {state: RootState}>('simCards/initSimcards',
  async (_, { dispatch, getState }) => {
  dispatch(simCardSlice.actions.clearSimCards())
  if (getState().simCardsReducer.simCardRelatedPartiesList.length === 0) {
    //No related parties, so we get fixed set of simcards to our customer
    const relatedParty = "0";
    const selectedProduct = getSelectedProductSelector(getState());
    const simCards = await getSimCards(getState().userInfoSliceReducer.customerNr, selectedProduct?.id);
    dispatch(setRelatedPartyId(relatedParty));
    dispatch(pushSimCardList({relatedParty, simCards}))
  }
})

const simCardSlice = createSlice({
  name: 'simCards',
  initialState,
  reducers: {
      setSimCardsListRelatedParties(state, action: PayloadAction<CustomerDTO[]>){
          state.simCardRelatedPartiesList = action.payload;
          state.selectedSimMsisdn = undefined;
          state.selectedRelatedPartyId = undefined;
      },
      setSimCardMsisdn(state, action: PayloadAction<string | undefined>) {
          state.selectedSimMsisdn = action.payload;
      },
      setRelatedPartyId(state, action: PayloadAction<string>) {
          state.selectedRelatedPartyId = action.payload;
      },
      pushSimCardList(state, action: PayloadAction<{relatedParty: string, simCards: SimCardDTO[]}>) {
          const {relatedParty, simCards} = action.payload;
          state.simCardsMap = [...state.simCardsMap, {relatedParty, simCards}];
      },
      clearSimCards(state) {
        clearState(state);
      },
      clearSimCardsRelatedParties(state) {
        state.simCardRelatedPartiesList = initialState.simCardRelatedPartiesList
      }
  },
  extraReducers: (builder) => {
    builder.addCase(resetWorkflow, (state) => {
        clearState(state);
    })
  },
})

const findSimCards = (simCardsMap: SimCardMapEntry[], relatedPartyId: string) =>
    simCardsMap.find(elem => elem.relatedParty === relatedPartyId)?.simCards

export const getSelectedSimCardsGivenRelatedParty = createSelector(
    (rootState: RootState) => rootState.simCardsReducer,
    (simCardState) => {
        const relatedPartyId = simCardState.selectedRelatedPartyId;
        if (relatedPartyId !== undefined) {
            return findSimCards(simCardState.simCardsMap, relatedPartyId);
        }
    }
)

export const getSelectedSimCard = createSelector(
    [(rootState: RootState) => rootState.simCardsReducer, getSelectedSimCardsGivenRelatedParty],
    (simCardState, simCards) => {
        if (simCardState.selectedSimMsisdn !== undefined && simCards !== undefined) {
            return simCards.find((elem) => {
                return elem.msisdn === simCardState.selectedSimMsisdn;
            });
        }
    }
)

const clearState = (state: SimCardContext) => {
    state.simCardsMap = initialState.simCardsMap
    state.selectedSimMsisdn = initialState.selectedSimMsisdn
    state.selectedRelatedPartyId = initialState.selectedRelatedPartyId
}

export const { pushSimCardList, setRelatedPartyId, setSimCardsListRelatedParties, setSimCardMsisdn, clearSimCardsRelatedParties } = simCardSlice.actions
export default simCardSlice.reducer