import { useDispatch } from 'react-redux';
import { GeocodeAddress } from '../../../store/workflow/MapSlice';
import { Order, OrderResponse } from '../../../store/workflow/models';
import MapSnapshotComponent from '../../map/MapSnapshotComponent';
import OrderDetails from '../../order/OrderDetails';
import HorizontalLine from '../../ui/common/HorizontalLine';
import ModalTemplate from '../../ui/common/ModalTemplate';
import classes from './ConfirmationModal.module.css';
import {goToBookingWorkflow, goToDashboard} from "../../../store/ApplicationSliceActions";
import { AppDispatch } from '../../../store/ReduxStore';
import {ScaleLoadingSpinner} from "@telekom/scale-components-react";
import usePDFGeneration from "../../../hooks/usePDFGeneration";

const ConfirmationModal = ({response, order, address}: {response: OrderResponse, order: Order, address?: GeocodeAddress}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { productIds } = response;
  const [isGeneratingPDF, generatedPDFUrls, handlePDFRequest] = usePDFGeneration()

  return (
    <ModalTemplate className={classes.confirmationModal}>
      <div className={classes.header}>
          <img src={require(`../../../assets/icons/actionresult/successTransparent.png`)} alt="Action Icon" />
          <div className="teleNeo32Bold" style={{marginLeft: 12}}>Vielen Dank für Ihre Buchung.</div>
      </div>
      <MapSnapshotComponent position={order.position?.point} />
      <div style={{marginTop: 16}}>
        <div className='teleNeo24Bold'>
          Buchungsbestätigung
        </div>
        <div className='teleNeo16Light' style={{marginTop: 2}}>
          Vielen Dank für Ihre Bestellung mit der Nummer {getProductId(productIds)}.
        </div>
      </div>

      <HorizontalLine />

      <div className='teleNeo16BoldLight'>
          {order.selectedProduct?.name}
      </div>

      <OrderDetails order={order} padding={16} address={address} />

      <HorizontalLine />

      <div className='teleNeo16Light'>
        Den aktuellen Status ihrer Buchung und weitere nützliche Informationen finden Sie in Ihrem 5G Dashboard.
      </div>

        <div style={{marginTop: 32, float: "right"}}>
            {isGeneratingPDF[response.orderId] ?
                <div style={{position: "absolute", left: 155}}><ScaleLoadingSpinner/></div> : generatedPDFUrls[response.orderId] ?
                    <button className="telekom-button-fat btn" style={{position: "absolute", left: 15}}><a
                        href={generatedPDFUrls[response.orderId]} rel="noreferrer" target="_blank" style={{textDecoration: "none"}}>Klicken
                        Sie hier, um das PDF zu öffnen</a></button> :
                    <button className="telekom-button-fat btn" style={{position: "absolute", left: 20}}
                            onClick={() => handlePDFRequest(response.orderId)}>Buchungsbestätigung herunterladen</button>}

            <button className="telekom-button-fat btn" onClick={() => dispatch(goToBookingWorkflow())}>Neue
                Bestellung
            </button>

            <button className="telekom-button-magenta telekom-button-fat btn" onClick={() => dispatch(goToDashboard())}
                    style={{marginLeft: 13}}> Zum Dashboard
            </button>
        </div>
    </ModalTemplate>
  )
}

const getProductId = (productIds: string[]) => productIds?.length > 0 ? productIds[0] : ""

export default ConfirmationModal