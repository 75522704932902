import {userLogo} from "../../../assets/Consts"
import SessionTimer from "./SessionTimer"
import UserInfromationBox from "./UserInformationBox";

export const DropdownMenu = ({ isAdminArea, profileName } : { isAdminArea: boolean, profileName?: string }) => {
    return (
        <div className="dropstart">
            <div>
                <div className="dropdown">
                    <button className="btn-primary-outline dropdown-toggle user-button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={userLogo} className="user-icon" alt="user" />{profileName}
                    </button>
                    <ul className="dropdown-menu user-info-box dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                        <UserInfromationBox isAdminArea={isAdminArea} />
                    </ul>
                </div>
            </div>
            <SessionTimer></SessionTimer>
        </div>
    )
}