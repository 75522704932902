import { ScaleButton, ScaleModal } from "@telekom/scale-components-react";
import { AdminComponentContent } from "./list/AdminList";
import { useState } from "react";
import classes from './DeleteConfirmationModal.module.css';
import { useAdminListContext } from "./list/AdminListContext";

const DeleteConfirmationModal = <ID,>({componentContent, deleteHandler}:
    {
        componentContent: Omit<AdminComponentContent<unknown, ID>, "tableHeaders">,
        deleteHandler: (formToDelete: ID) => Promise<number>,
    }) => {
    const [isSubmitButtonDisable, setSubmitButtonDisable] = useState(false)
    const { reloadTable, deleteId, setDeleteId } = useAdminListContext<unknown, ID>();

    const hideDeleteModal = () => setDeleteId(undefined)

    const deleteFormHandler = async () => {
        if (deleteId === undefined) {
            alert('No element to delete is selected')
            return
        }

        if (isSubmitButtonDisable) {
            return
        }

        setSubmitButtonDisable(true)
        try {
            await deleteHandler(deleteId)
            await reloadTable()
        } catch (e) {
            console.error(`Delete Error: ${JSON.stringify(e)}`)
        }
        hideDeleteModal()
        setSubmitButtonDisable(false)
    }

    const getAttributes = (elem?: ID): string => {
        if (!elem) {
            return ""
        }
        if (componentContent.getIdentifier) {
            return componentContent.getIdentifier(elem)
        }
        return Object.values(elem).join(", ")
    }
    
    return (
        <ScaleModal opened={deleteId !== undefined} onScale-close={hideDeleteModal} omitCloseButton={true} heading={`Delete this ${componentContent.formName}?`} size="small" data-testid="adminModalDeleteForm">
            <div className={classes.deleteModal}>
                <span>Deletion of {componentContent.formName} <b>[{getAttributes(deleteId)}]</b> cannot be undone</span>
                
                <div className={classes.actionButtons}>
                    <ScaleButton slot="action" onClick={deleteFormHandler} disabled={isSubmitButtonDisable}>Delete</ScaleButton>
                    <ScaleButton slot="action" variant="secondary" onClick={hideDeleteModal}>
                        Abbrechen
                    </ScaleButton>
                </div>
            </div>
        </ScaleModal>
    )
}

export default DeleteConfirmationModal;