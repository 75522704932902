import { z } from "zod";
import * as api from "../../api";
import { authHeader } from "../../apiHeader";
import {
  FormToIdFunction,
  PaginationRequest,
  PaginationResponse,
} from "../../api";

export const statusValues = ["ACTIVE", "INACTIVE"] as const;
export type Status = (typeof statusValues)[number];

export const CustomerBase = z.object({
  number: z.string(),
  name: z.string(),
  rvid: z.string().optional(),
});

export const CustomerType = CustomerBase.extend({
  status: z.enum(statusValues),
  statusLastModified: z.string(),
});

export const CreateCustomerType = CustomerBase.extend({
  userFirstName: z.string(),
  userLastName: z.string(),
  userEmail: z.string(),
});

export type Customer = z.infer<typeof CustomerType>;
export type CreateCustomer = z.infer<typeof CreateCustomerType>;

export type Id = Pick<Customer, "number">;

const customersUrl = `${process.env.REACT_APP_BACKEND_API_URL}/customer`;

export const formToId: FormToIdFunction<Customer, Id> = (
  customer: Customer
) => ({
  number: customer.number,
});

export const getCustomers = (
  paginationRequest: PaginationRequest<Customer>
): Promise<PaginationResponse<Customer[]>> =>
  api.authorizedGet<api.PaginationResponse<Customer[]>>(
    api.buildGetUrlWithParameters(customersUrl, paginationRequest),
    authHeader()
  );

export const getCustomerData = async (customerNr: string | undefined) =>
  await api.authorizedPost<Customer>(`${customersUrl}/data`, authHeader(), {
    customerNr,
  });

export const getCustomerWithCustomerNr = async (
  customerNr: string | undefined
): Promise<PaginationResponse<Customer[]>> => {
  const customer = await getCustomerData(customerNr);
  return {
    totalElements: 1,
    content: [customer],
  };
};

export const postCustomer = (form: CreateCustomer) =>
  api.authorizedPostNoBody(customersUrl, authHeader(), form);

export const putCustomer = (form: Customer) => {
  const { number, statusLastModified, ...putForm } = form;
  return api.authorizedPutNoBody(
    `${customersUrl}/${number}`,
    authHeader(),
    putForm
  );
};

export const deleteCustomer = (deleteForm: Id) =>
  api.authorizedPutNoBody(
    `${customersUrl}/${deleteForm.number}`,
    authHeader(),
    {
      status: "INACTIVE",
    }
  );
