import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetWorkflow } from './CommonActions';
import { isDtRole, Role } from './UserInfoSlice';
export type ApplicationState = OrderingAreaState | AdminPanelState;

const bookingWorkflowValues = ["LEFT_PANEL_HIDDEN", "SEARCH_SCREEN", "TIME_AND_DATE_CARD_SCREEN", "SUMMARY_SCREEN", "SEND_ORDER_SCREEN"] as const;

const orderingAreaValues = ["PRODUCT_SELECTION_SCREEN", "DASHBOARD_SCREEN", ...bookingWorkflowValues] as const;
export type OrderingAreaState = typeof orderingAreaValues[number]

const adminPanelCustomerValues = ["SIMCARDS_TAB", "ORDERS_TAB", "CUSTOMERS_TAB", "CUSTOMERS_USER_TAB"] as const;

const adminPanelValues = [...adminPanelCustomerValues, "CONFIGURATION_TAB", "CUSTOMER_CONFIGURATION_TAB"] as const;
export type AdminPanelState = typeof adminPanelValues[number];

export const initialState: { applicationState: ApplicationState, leftPanelDisabled: boolean } = {
    applicationState: "PRODUCT_SELECTION_SCREEN",
    leftPanelDisabled: false
}

const arrayIncludesState = (array: unknown, state: ApplicationState) => (array as readonly string[]).includes(state)

export const isWorkflowState = (state: ApplicationState) => arrayIncludesState(bookingWorkflowValues, state);

export const isOrderingAreaState = (state: ApplicationState) => arrayIncludesState(orderingAreaValues, state);

const isDTTab = (state: ApplicationState) => isOrderingAreaState(state) || arrayIncludesState(adminPanelValues, state)

const isCustomerTab = (state: ApplicationState) => isOrderingAreaState(state) || arrayIncludesState(adminPanelCustomerValues, state)

export const isTabPresented = (role: Role | undefined, state: ApplicationState) => isDtRole(role) ? isDTTab(state) : isCustomerTab(state)

export const getDefaultTab = (isAdminArea: boolean, role: Role | undefined): ApplicationState => isAdminArea ? "PRODUCT_SELECTION_SCREEN" :
  isDtRole(role) ? "CONFIGURATION_TAB" : "CUSTOMERS_TAB"

const applicationState = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationState(state, action: PayloadAction<ApplicationState> ) {
      state.applicationState = action.payload;
    },
    goNext(state) {
      switch(state.applicationState) {
        case "PRODUCT_SELECTION_SCREEN":
          state.applicationState = "LEFT_PANEL_HIDDEN";
          break;
        case "SEARCH_SCREEN":
          state.applicationState = "TIME_AND_DATE_CARD_SCREEN";
          break;
        case "TIME_AND_DATE_CARD_SCREEN":
          state.applicationState = "SUMMARY_SCREEN";
          break;
        case "SUMMARY_SCREEN":
          state.applicationState = "SEND_ORDER_SCREEN";
          break;
      }
    },
    goBack(state) {
      switch(state.applicationState) {
        case "SEARCH_SCREEN":
          state.applicationState = "LEFT_PANEL_HIDDEN";
          break;
        case "TIME_AND_DATE_CARD_SCREEN":
          state.applicationState = "SEARCH_SCREEN";
          break;
        case "SUMMARY_SCREEN":
          state.applicationState = "TIME_AND_DATE_CARD_SCREEN";
          break;
        case "SEND_ORDER_SCREEN":
          state.applicationState = "SUMMARY_SCREEN";
          break;
      }
    },
    setLeftPanelDisabled(state, action: PayloadAction<boolean>) {
      state.leftPanelDisabled = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetWorkflow, () => initialState)
  },
})

export const { goNext, goBack, setApplicationState, setLeftPanelDisabled } = applicationState.actions
export default applicationState.reducer