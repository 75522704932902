import { FormToIdFunction } from "../../../../../api/api"
import { ZodTypeWithShape } from "../AdminForm"
import { AdminList, AdminListParams } from "./AdminList"
import AdminListContextProvider from "./AdminListContext"

type AdminListWrapperParams<FORM, ID> = {
    zodType: ZodTypeWithShape<FORM>,
    formToId: FormToIdFunction<FORM, ID>,
} & AdminListParams<FORM, ID>

export const AdminListWrapper = <FORM,ID = Partial<FORM>>({componentContent, restOperations, zodType, visibilityConfiguration, formToId, children, childForm}: AdminListWrapperParams<FORM, ID>) => {

        return (
            <AdminListContextProvider initialState={{sortBy: componentContent.tableHeaders[0].value, zodType}} restOperations={restOperations} isVisible={visibilityConfiguration.isVisible} formToId={formToId}>
                <AdminList
                    componentContent={componentContent}
                    restOperations={restOperations}
                    visibilityConfiguration={visibilityConfiguration}
                    childForm={childForm}
                >
                    {
                        children
                    }
                </AdminList>
            </AdminListContextProvider>
        )
    }