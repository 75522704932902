import { ApplicationState } from '../../store/ApplicationSlice';
import RoleConfigurationListComponent from '../../components/sections/admin/RoleConfigurationListComponent';

const BookingConfigurationPage = ({applicationState}: { applicationState: ApplicationState }) => {
    return (
        <>
            <RoleConfigurationListComponent applicationState={applicationState} />
        </>
    )
}

export default BookingConfigurationPage