import { Context, createContext, Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState } from "react";
import { FormState, RestOperations } from "./AdminList";
import { initForm, ZodTypeWithShape } from "../AdminForm";
import { FormToIdFunction } from "../../../../../api/api";

export type AdminListContextType<FORM, ID> = {
    sortBy: keyof FORM,
    selected: FormState<Partial<FORM>> | undefined,
    pageNumber: number,
    pageSize: number,
    totalElements: number,
    rows: FORM[] | undefined,
    deleteId: ID | undefined,
    zodType: ZodTypeWithShape<FORM>,
    formToId: FormToIdFunction<FORM, ID>,
    setSortBy: Dispatch<SetStateAction<keyof FORM>>,
    setSelected: Dispatch<SetStateAction<FormState<Partial<FORM>> | undefined>>
    setPageNumber: Dispatch<SetStateAction<number>>,
    setPageSize: Dispatch<SetStateAction<number>>,
    setDeleteId: Dispatch<SetStateAction<ID | undefined>>,
    setZodType: Dispatch<SetStateAction<ZodTypeWithShape<FORM>>>,
    openNewForm: () => void,
    reloadTable: () => Promise<void>
}

const AdminListContext = createContext<AdminListContextType<unknown, unknown>>(undefined as unknown as AdminListContextType<unknown, unknown>);
type AdminListContextProviderInitState<FORM> = Pick<AdminListContextType<FORM, unknown>, "sortBy"> & {
    zodType: ZodTypeWithShape<FORM>
}

const AdminListContextProvider = <FORM, ID = Partial<FORM>>({initialState, restOperations, isVisible, formToId, children}:
    {
        initialState: AdminListContextProviderInitState<FORM>,
        restOperations: Pick<RestOperations<FORM, unknown>, "getList">,
        isVisible: boolean,
        formToId: FormToIdFunction<FORM, ID>,
        children: ReactElement
    }) => {
    const [sortBy, setSortBy] = useState<keyof FORM>(initialState.sortBy);
    const [selected, setSelected] = useState<FormState<Partial<FORM>> | undefined>(undefined);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [rows, setRows] = useState<FORM[] | undefined>();
    const [deleteId, setDeleteId] = useState<ID | undefined>(undefined)
    const [zodType, setZodType] = useState<ZodTypeWithShape<FORM>>(initialState.zodType)

    const reloadTable = async () => {
        try {
            const val = await restOperations.getList({
                page: pageNumber.toString(),
                size: pageSize.toString(),
                sortBy,
                sortDirection: "ASC"
            })

            setRows(val.content)
            setTotalElements(val.totalElements)
        } catch(e) {
            console.error(e)
            setRows(undefined)
            setTotalElements(0)
        }
    }

    const openNewForm = () => {
        setSelected({
            form: initForm,
            isCreateMode: true
        })
    }

    useEffect(() => {
        if (isVisible) {
            reloadTable()
        }
    }, [isVisible, pageNumber, pageSize, sortBy])

    const contextValue: AdminListContextType<FORM, ID> = {
        sortBy,
        selected,
        pageNumber,
        pageSize,
        totalElements,
        rows,
        deleteId,
        zodType,
        formToId,
        setSortBy,
        setSelected,
        setPageNumber,
        setPageSize,
        setDeleteId,
        setZodType,
        openNewForm,
        reloadTable
    };

    return <AdminListContext.Provider value={contextValue as unknown as AdminListContextType<unknown, unknown>}>
        {children}
    </AdminListContext.Provider>
}

export default AdminListContextProvider;

export const useAdminListContext = <FORM, ID>() => useContext<AdminListContextType<FORM, ID>>(AdminListContext as unknown as Context<AdminListContextType<FORM, ID>>);