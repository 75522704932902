import { ApplicationState } from "../../../store/ApplicationSlice";
import {
  Customer,
  CustomerType,
  deleteCustomer,
  formToId,
  getCustomers,
  getCustomerWithCustomerNr,
  postCustomer,
  putCustomer,
} from "../../../api/backend/admin/customerApi";
import { AdminListWrapper } from "./common/list/AdminListWrapper";
import CustomerForm from "./CustomerForm";
import { AdminFormChild } from "./common/AdminForm";
import { onlyDtEditorRoles } from "./common/list/FormTable";
import { useAppSelector } from "../../../store/ReduxStore";
import { isDtRole } from "../../../store/UserInfoSlice";

const CustomerListComponent = ({
  applicationState,
}: {
  applicationState: ApplicationState;
}) => {
  const { role, customerNr } = useAppSelector(
    (state) => state.userInfoSliceReducer
  );

  return (
    <AdminListWrapper
      componentContent={{
        tableName: "Kundenstammdaten",
        formName: "Kunde",
        tableHeaders: [
          {
            name: "Kundenname",
            value: "name",
          },
          {
            name: "Kundennummer",
            value: "number",
          },
          {
            name: "Rahmenvertragsnummer",
            value: "rvid",
          },
          {
            name: "Status",
            value: "status",
          },
          {
            name: "aktiv/gekündigt seit",
            value: "statusLastModified",
          },
        ],
      }}
      restOperations={{
        getList: isDtRole(role)
          ? getCustomers
          : () => getCustomerWithCustomerNr(customerNr),
        createElement: postCustomer as (form: unknown) => Promise<number>,
        modifyElement: putCustomer,
        deleteElement: deleteCustomer,
      }}
      visibilityConfiguration={{
        isVisible: applicationState === "CUSTOMERS_TAB",
        isShowEditControls: onlyDtEditorRoles,
        deleteButtonDisabled: (row) => row.status === "INACTIVE",
      }}
      zodType={CustomerType}
      formToId={formToId}
      childForm={CustomerForm() as AdminFormChild<Customer>}
    >
      {(row) => (
        <>
          <td>{row.name}</td>
          <td>{row.number}</td>
          <td>{row.rvid}</td>
          <td>{row.status}</td>
          <td>{row.statusLastModified}</td>
        </>
      )}
    </AdminListWrapper>
  );
};

export default CustomerListComponent;
