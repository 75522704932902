import Map from '../../components/map/MapComponent';
import SendOrderComponent from '../../components/sections/sendOrder/SendOrderComponent';
import LeftPanel from '../../components/ui/layout/LeftPanel';
import { ApplicationState, isWorkflowState } from '../../store/ApplicationSlice';
import { Point } from '../../store/workflow/models';

const BookingWorkflowPage = ({applicationState}: { applicationState: ApplicationState }) => {
    const showWorkflow = isWorkflowState(applicationState);
    const initPosition: Point = {
      lat: 52.517037,
      lng: 13.38886
    };

  return (
    showWorkflow ?
      <div style={{display: "flex", flexDirection: "row"}} className="workflow">
        <SendOrderComponent applicationState={applicationState} />
        <LeftPanel />
        <Map initPosition={initPosition} applicationState={applicationState} />
      </div>
    : null
    )
}

export default BookingWorkflowPage