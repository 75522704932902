import classes from "./DetailCard.module.css";
import {ScaleIconActionSuccess} from "@telekom/scale-components-react";
import {UserOrder} from "../../store/workflow/models";
import {useEffect, useRef} from "react";
import mapboxgl, {LngLat} from "mapbox-gl";
import {MapWrapper} from "../../components/map/mapWrapper/MapWrapper";
import {getStyledTag} from "./StyledTag";
import { dateAndDuration } from "../../components/utils/OrderDetailsFormatter";

const DetailCard = ({currentOrder, isFromDt}: { currentOrder: UserOrder, isFromDt?: boolean }) => {
    const mapContainerRef = useRef<HTMLDivElement | null>(null);
    const mapRef = useRef<MapWrapper | null>(null);

    useEffect(() => {
        const mapAccessToken = process.env.REACT_APP_MAP_ACCESS_TOKEN;
        const position = currentOrder?.position;
        if (mapRef.current || mapContainerRef.current == null) return;
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: '/osm_telekom_light.json',
            center: position,
            zoom: 15,
            dragRotate: false,
            accessToken: mapAccessToken
        });
        map.scrollZoom.disable();
        map.dragPan.disable();

        if (position !== undefined) {
            new mapboxgl.Marker({draggable: false})
                .setLngLat(new LngLat(position.lng, position.lat))
                .addTo(map)
        }
    }, [currentOrder?.position]);

    return (
        <>
            <div className={classes.detailContainer}>

                {getStyledTag(currentOrder.status)}
                <div>
                    {displayOrderName(currentOrder)}
                </div>
                <div className={`teleNeo16`}
                     style={{color: 'rgba(0, 0, 0, 0.65)'}}>Bestellnr.: {currentOrder.noOrder}</div>
                <div className={`teleNeo16`}>{dateAndDuration(currentOrder)}</div>
                <div className={`teleNeo16`} style={{color: 'rgba(0, 0, 0, 0.65)'}}>Zugeordnete SIM-Karte</div>
                <div  className={`teleNeo16`}>
                    { isFromDt ? <div>IMSI: {currentOrder.imsi}</div> : null }
                    <div>ICCID: {currentOrder.iccid}</div>
                    <div>MSISDN: {currentOrder.msisdn}</div>
                </div>
                <div className={`teleNeo16`} style={{color: 'rgba(0, 0, 0, 0.65)'}}>Adresse</div>
                <div className={`teleNeo16`}>
                    <div>{currentOrder.address?.street ? (`${currentOrder.address?.street}, `) : ""}{currentOrder.address?.zipCode} {currentOrder.address?.city}</div>
                    <div>{currentOrder.position?.lat}° N, {currentOrder.position?.lng}° O</div>
                </div>
                <div className={classes.statusLabel}><ScaleIconActionSuccess color="#008653"/>Verfügbar</div>

            </div>
            <div className={classes.mapContainer} ref={mapContainerRef}/>
        </>
    )
}

export const displayOrderName = (order: UserOrder) => {
    return <>
        <div className={`teleNeo24`}>{order.description}</div>
        <div className={`teleNeo16`}>{order.customerName}</div>
    </>
}

export default DetailCard
