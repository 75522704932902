import classes from "./UserInformationBox.module.css";
import {useDispatch} from "react-redux";
import {AppDispatch, RootState, useAppSelector} from "../../../store/ReduxStore";
import {ScaleDivider} from "@telekom/scale-components-react";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { RESET_WORKFLOW } from "../../../store/CommonActions";
import { Action, CombinedState, ThunkDispatch } from "@reduxjs/toolkit";
import { clearUserInfo, Role } from "../../../store/UserInfoSlice";
import { getDefaultTab, setApplicationState } from "../../../store/ApplicationSlice";
import { clearSimCardsRelatedParties } from "../../../store/workflow/SimCardsSlice";
import { goToBookingWorkflow } from "../../../store/ApplicationSliceActions";

const UserInformationBox = ({isAdminArea}: {isAdminArea: boolean}) => {
    const auth = useAuth();
    const dispatch = useDispatch<AppDispatch>();
    const role: Role | undefined = useAppSelector((state) => state.userInfoSliceReducer.role);

    const clickChangeAreaAction = () => {
        if (isAdminArea) {
            dispatch(goToBookingWorkflow())
        } else {
            dispatch(setApplicationState(getDefaultTab(isAdminArea, role)))
        }
    }

    return (
        <div className={classes.userBox}>
            <div className={classes.userTitle}>
                <div className={`title-text {classes.userText}`}>User</div>
                <div className={`teleNeo16Light {classes.emailText}`}>{auth.user?.profile.email}</div>
            </div>
            <ScaleDivider />
            <div style={{padding: '24px 24.5px'}}><button className={`btn-primary-outline teleNeo16 ${classes.logoutButton}`} onClick={() => logoutAction(auth, dispatch)}>Logout</button></div>

            <ScaleDivider />
            <div style={{padding: '24px 24.5px'}}><button className={`btn-primary-outline teleNeo16 ${classes.logoutButton}`} onClick={clickChangeAreaAction}>{isAdminArea ? 'Booking Order' : 'Admin'}</button></div>
        </div>
    )
}

export const logoutAction = async (auth: AuthContextProps, dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => {
    dispatch({type: RESET_WORKFLOW})
    dispatch(clearSimCardsRelatedParties())
    dispatch(clearUserInfo())
    await auth.signoutSilent();
}

export default UserInformationBox