import { Role } from "../../store/UserInfoSlice";
import * as api from "../api";
import { authHeader } from "../apiHeader";

const versionUrl = `${process.env.REACT_APP_BACKEND_URL}/actuator/info`;
type ActuatorInfo = {
    buildVersion: string;
    git?: {
        commit?: {
            id: string
        }
    }
}

export interface UserInfoResponse {
    role?: Role;
    customer?: {
        number: string
    }
}

export const getVersion = () => api.authorizedGet<ActuatorInfo>(versionUrl, authHeader());

export const getUserInfo = () => api.authorizedGet<UserInfoResponse>(`${process.env.REACT_APP_BACKEND_API_URL}/whoami`, authHeader());