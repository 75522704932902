import {ApplicationState} from "../../store/ApplicationSlice";
import classes from "./DashboardPage.module.css";
import "mapbox-gl/dist/mapbox-gl.css"
import {UserOrder} from "../../store/workflow/models";
import DetailCard from "./DetailCard";
import OrdersTable from "./OrdersTable";
import {RootState, useAppSelector} from "../../store/ReduxStore";
import {getCurrentOrderSelector} from "../../store/UserOrdersSlice";
import {useSelector} from "react-redux";
import { isFromDtSelector } from "../../store/UserInfoSlice";

export type DashboardType = "DASHBOARD_SCREEN" | "ORDERS_TAB"

const DashboardPage = ({dashboardType, applicationState}: { dashboardType: DashboardType, applicationState: ApplicationState }) => {
    const showDashboardPage = applicationState === dashboardType;
    const { userOrdersList } = useAppSelector((state) => {
        return state.userOrdersReducer;
    });
    const currentOrder = useSelector<RootState, UserOrder | undefined>(getCurrentOrderSelector) ?? {id: '', name: '', noOrder: '', description: ''};
    const isFromDt = useSelector<RootState, boolean | undefined>(isFromDtSelector);

    return showDashboardPage ?
            <div className={classes.mainContainer}>
                <div className={classes.headerContainer}>
                    <p className={`teleNeo42 ${classes.headerText}`}>5G Dashboard</p>
                    <p className={`teleNeo16 ${classes.headerText}`}>Buchungsübersicht</p>
                </div>

                <div className={classes.diagramsContainer}>

                    {
                            <>
                                {
                                    userOrdersList.length !== 0 ?
                                        <div className={classes.cardContainer}>
                                            <DetailCard currentOrder={currentOrder} isFromDt={isFromDt}/> </div> : null

                                }

                                <div className={classes.tableContainer}>
                                    <OrdersTable userOrdersList={userOrdersList} isFromDt={isFromDt} dasboardType={dashboardType} />
                                </div>
                            </>
                    }

                </div>
            </div>
        : null
}

export default DashboardPage