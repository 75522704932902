import {ApplicationState} from "../../../store/ApplicationSlice";
import { useAppSelector } from "../../../store/ReduxStore";
import ActionResult from "../../common/ActionResult";
import SelectedPlace from "./selectedPlace/SelectedPlace";

const SearchArea = ({applicationState} : {applicationState: ApplicationState}) => {
  const showSearchPanel = applicationState === "SEARCH_SCREEN";
  const { isPositionValid } = useAppSelector((state) => state.mapReducer);

  return (
    <>
      {
        showSearchPanel &&
        <>
          <SelectedPlace />
          <ActionResult isSuccess={isPositionValid}>
            Service an ausgewähltem<br/>
            {isPositionValid ? "Standort verfügbar." : "Standort nicht verfügbar. Bitte neue Anfrage tätigen."}
          </ActionResult>
        </>
      }
    </>
  )
}

export default SearchArea