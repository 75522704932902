import { UserOrder } from "../../store/workflow/models";
import { useEffect, useMemo, useState } from "react";
import {
  ScaleButton,
  ScaleCheckbox,
  ScaleIconActionDownload,
  ScaleIconActionRemove,
  ScaleIconUserFilePdfFile,
  ScaleLoadingSpinner,
  ScalePagination,
  ScaleTable,
  ScaleTag,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
  ScaleTextField,
} from "@telekom/scale-components-react";
import {
  ScaleDropdownSelectCustomEvent,
  ScalePaginationCustomEvent,
  ScaleTextFieldCustomEvent,
} from "@telekom/scale-components-neutral";
import { PaginationEventDirection } from "@telekom/scale-components/dist/types/components/pagination/pagination";
import { getStyledTag } from "./StyledTag";
import classes from "./OrdersTable.module.css";
import orderBy from "lodash/orderBy";
import { dateAndDuration } from "../../components/utils/OrderDetailsFormatter";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/ReduxStore";
import { setCurrentOrder } from "../../store/UserOrdersSlice";
import {
  getLat,
  getLng,
} from "../../components/sections/search/selectedPlace/SelectedPlace";
import { displayOrderName } from "./DetailCard";
import { goToDashboard } from "../../store/ApplicationSliceActions";
import debounce from "lodash/debounce";
import { cancelOrder } from "../../api/backend/orderApi";
import CancelOrderConfirmationModal from "./CancelOrderConfirmationModal";
import usePDFGeneration from "../../hooks/usePDFGeneration";
import { InputChangeEventDetail } from "@telekom/scale-components/dist/types/components/input/input";
import { DashboardType } from "./DashboardPage";
import moment from "moment";

type SearchCriteria = Pick<UserOrder, "customerName" | "msisdn" | "status">;

const OrdersTable = ({
  userOrdersList,
  isFromDt,
  dasboardType,
}: {
  userOrdersList: UserOrder[];
  isFromDt?: boolean;
  dasboardType: DashboardType;
}) => {
  const ORDERS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [index, setIndex] = useState<number>(0);
  const tagsNames = ["Past", "Planned", "Live", "Cancelled"];
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({});
  const [tagIndex, setTagIndex] = useState<number>(-1);
  const [sortingKey, setSortingKey] = useState<string[]>([""]);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const [isGeneratingPDF, generatedPDFUrls, handlePDFRequest] =
    usePDFGeneration();

  const checkIndex = (id: number) => {
    return index === id;
  };

  const filteredList = useMemo(() => {
    const filteredList = filterByCriteria(userOrdersList, searchCriteria);

    const directionArray = Array.from(sortingKey, () => sortDirection);

    return orderBy(filteredList, sortingKey, directionArray);
  }, [sortingKey, sortDirection, searchCriteria, userOrdersList]);

  const customerNames = useMemo(() => {
    const customerNames = Array.from(
      new Set(
        userOrdersList
          .map((order) => order.customerName)
          .filter((name) => name !== undefined)
      )
    );
    customerNames.unshift("");
    return customerNames;
  }, [userOrdersList]);

  const getCurrentRow = (selectedRow: number) =>
    currentPage * ORDERS_PER_PAGE + selectedRow;

  const clickHandler = (selectedRow: number) => {
    const currentRow = getCurrentRow(selectedRow);

    setIndex(currentRow);
  };

  const getStart = () => ORDERS_PER_PAGE * currentPage;

  const getEnd = () => {
    const end = ORDERS_PER_PAGE * (currentPage + 1);
    if (end > filteredList.length) {
      return filteredList.length;
    }
    return end;
  };

  const handlePagination = (
    event: ScalePaginationCustomEvent<{
      startElement?: number;
      direction: PaginationEventDirection;
    }>
  ) => {
    const direction = event.detail.direction;
    const allOrders = filteredList.length;

    if (direction === "NEXT") {
      setCurrentPage((currentPage) => currentPage + 1);
    } else if (direction === "PREVIOUS") {
      setCurrentPage((currentPage) => currentPage - 1);
    } else if (direction === "LAST") {
      setCurrentPage(Math.floor(allOrders / ORDERS_PER_PAGE));
    } else if (direction === "FIRST") {
      setCurrentPage(0);
    }
  };

  const seperateDateFromTime = (index: number) => {
    const currentOrder = getCurrentOrder(index);
    if (currentOrder) {
      return dateAndDuration(currentOrder);
    }
  };

  const getActionButtons = (index: number, now: Date) => {
    const order = getCurrentOrder(index);
    switch (order?.status) {
      case "Past":
      case "Live":
        return (
          <>
            {pdfTableButton(order.id)}
            <td>
              <ScaleIconActionRemove color="hsla(0, 0%, 50%, 0.75)" />
            </td>
          </>
        );
      case "Planned":
        return (
          <>
            {pdfTableButton(order.id)}
            <td>
              {canBeCancelled(order.currentUserCancellationDeadline, now) ? (
                <ScaleIconActionRemove
                  onClick={() => setShowCancelOrderModal(true)}
                />
              ) : (
                <ScaleIconActionRemove color="hsla(0, 0%, 50%, 0.75)" />
              )}
            </td>
          </>
        );
      default:
        return (
          <>
            <td></td>
            <td></td>
          </>
        );
    }
  };

  const pdfTableButton = (orderId: string) => {
    return (
      <>
        <td>
          {isGeneratingPDF[orderId] ? (
            <ScaleLoadingSpinner />
          ) : generatedPDFUrls[orderId] ? (
            <a
              href={generatedPDFUrls[orderId]}
              rel="noreferrer"
              target="_blank"
            >
              <ScaleIconActionDownload />
            </a>
          ) : (
            <ScaleIconUserFilePdfFile
              onClick={() => handlePDFRequest(orderId)}
            />
          )}
        </td>
      </>
    );
  };

  const checkFirstElementInTable = () => {
    clickHandler(0);
    setCurrentPage(0);
    setIndex(0);
  };

  const getTagIndexHandler = (id: number) => {
    const filterId = tagIndex === -1 || tagIndex !== id ? id : -1;

    debouncedSearch(
      "status",
      filterId === -1 ? undefined : tagsNames.at(filterId)
    );
    setTagIndex(filterId);
  };

  const getCurrentOrder = (index: number): UserOrder =>
    filteredList.at(index) ?? filteredList[0];

  useEffect(() => {
    let currentOrderNo = undefined;
    if (filteredList.length > 0) {
      currentOrderNo = getCurrentOrder(index).noOrder;
    }
    dispatch(setCurrentOrder(currentOrderNo));
  }, [index, filteredList.length]);

  function sortTable(title: string[]) {
    setSortingKey(title);

    setSortDirection(sortDirection === "asc" ? "desc" : "asc");

    checkFirstElementInTable();
  }

  const debouncedSearch = debounce(
    async <K extends keyof SearchCriteria>(key: K, value: SearchCriteria[K]) =>
      setSearchCriteria((prev) => ({
        ...prev,
        [key]: value,
      })),
    300
  );

  const handleMsisdnFilterChange = (
    event: ScaleTextFieldCustomEvent<InputChangeEventDetail>
  ) => {
    debouncedSearch("msisdn", String(event.target.value));
  };

  const handleCustomerNameFilterChange = (
    event: ScaleDropdownSelectCustomEvent<unknown>
  ) => {
    debouncedSearch("customerName", event.target.value);
  };

  const cancelHandler = async () => {
    setIsCancelling(true);
    const order = getCurrentOrder(index);
    await cancelOrder(order.noOrder, order.customerNr)
      .then(() => {
        setTimeout(() => {
          setIsCancelling(false);
          setShowCancelOrderModal(false);
          dispatch(goToDashboard());
        }, 2000);
      })
      .catch(() => {
        setIsCancelling(false);
      });
  };

  const now = new Date();

  return (
    <>
      <div className={classes.filterContainer}>
        <div className={classes.searchPanel}>
          <div>
            <div className={`teleNeo16`}>Filter</div>
            <div className={classes.filterPanel}>
              {tagsNames.map((tag, id) => {
                return (
                  <div key={`div-${id}`} onClick={() => getTagIndexHandler(id)}>
                    <ScaleTag
                      size="small"
                      style={
                        tagIndex === id
                          ? { "--background": "#242426", "--color": "white" }
                          : { "--background": "#E7E7E9", "--color": "black" }
                      }
                      key={id}
                    >
                      {tag}
                    </ScaleTag>
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <div className={`teleNeo16`}>Search</div>
            </div>
            <div style={{ display: "flex", columnGap: "10px" }}>
              <div className={classes.searchField}>
                <div className="input-group">
                  <ScaleTextField
                    label="Search by MSISDN"
                    onScaleChange={handleMsisdnFilterChange}
                  ></ScaleTextField>
                </div>
              </div>
              {isFromDt ? (
                <div className={classes.searchField}>
                  <div className="input-group">
                    <ScaleDropdownSelect
                      label="Search by Customer name"
                      onScale-change={handleCustomerNameFilterChange}
                      style={{ width: "100%" }}
                    >
                      {customerNames.map((e, key) => {
                        return (
                          <ScaleDropdownSelectItem
                            key={key}
                            value={e}
                            selected={false}
                          >
                            {e}
                          </ScaleDropdownSelectItem>
                        );
                      })}
                    </ScaleDropdownSelect>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <ScaleButton
            onClick={() => dispatch(goToDashboard())}
            className={`${classes.button}`}
          >
            Neu laden
          </ScaleButton>
        </div>
      </div>
      <div className={classes.table}>
        <ScaleTable show-sort id="tableWithProducts">
          <table>
            <thead>
              <tr>
                <th id="checkbox" aria-disabled="true"></th>
                <th
                  aria-sort={`${sortDirection}ending`}
                  id="name"
                  onClick={() => sortTable(["description"])}
                >
                  Titel
                </th>
                <th
                  aria-sort={`${sortDirection}ending`}
                  id="status"
                  onClick={() => sortTable(["status"])}
                >
                  Status
                </th>
                <th
                  aria-sort={`${sortDirection}ending`}
                  id="msisdn"
                  onClick={() => sortTable(["msisdn"])}
                >
                  Zugeordnete SIM-Karten
                </th>
                <th
                  aria-sort={`${sortDirection}ending`}
                  id="address"
                  onClick={() =>
                    sortTable([
                      "address.street",
                      "address.zipCode",
                      "address.city",
                    ])
                  }
                >
                  Standort
                </th>
                <th
                  aria-sort={`${sortDirection}ending`}
                  id="date"
                  onClick={() => sortTable(["date"])}
                >
                  Buchungszeitraum
                </th>
                {dasboardType === "DASHBOARD_SCREEN" ? (
                  <>
                    <th aria-disabled="true">Aktion</th>
                    <th aria-disabled="true"></th>
                  </>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {filteredList.slice(getStart(), getEnd()).map((order, id) => {
                const orderIndex = getCurrentRow(id);
                return (
                  <tr
                    id={id.toString()}
                    onClick={() => clickHandler(id)}
                    key={id}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      <ScaleCheckbox
                        id={orderIndex.toString()}
                        checked={checkIndex(orderIndex)}
                      />
                    </td>
                    <td>{displayOrderName(order)}</td>
                    <td>{getStyledTag(order.status)}</td>
                    <td>{order?.msisdn}</td>
                    <td>
                      {getLat(order?.position)}, {getLng(order?.position)}
                    </td>
                    <td>{seperateDateFromTime(orderIndex)}</td>
                    {dasboardType === "DASHBOARD_SCREEN"
                      ? getActionButtons(orderIndex, now)
                      : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ScaleTable>
        <ScalePagination
          pageSize={ORDERS_PER_PAGE}
          startElement={getStart()}
          totalElements={filteredList.length}
          ariaLabelFirstPage="Zur ersten Seite"
          ariaLabelLastPage="Zur nächsten Seite"
          ariaLabelPreviousPage="Zur vorigen Seite"
          ariaLabelNextPage="Zur letzten Seite"
          onScale-pagination={handlePagination}
        ></ScalePagination>
      </div>
      {showCancelOrderModal ? (
        <CancelOrderConfirmationModal
          confirmHandler={cancelHandler}
          closeHandler={() => {
            setShowCancelOrderModal(false);
          }}
          isCancelling={isCancelling}
        />
      ) : (
        ""
      )}
    </>
  );
};

export const canBeCancelled = (
  cancellationDeadline: string | undefined,
  now: Date
): boolean => {
  if (!cancellationDeadline) {
    return false;
  }
  return moment(cancellationDeadline).toDate() > now;
};

export const filterByCriteria = (
  userOrdersList: UserOrder[],
  searchCriteria: SearchCriteria
): UserOrder[] =>
  Object.entries(searchCriteria)
    .filter(([, value]) => value)
    .reduce(
      (accumulatedOrders, [key, value]) =>
        accumulatedOrders.filter((order) => {
          return order[key as keyof SearchCriteria]?.includes(value);
        }),
      userOrdersList
    );

export default OrdersTable;
