import { ScaleDropdownSelect, ScaleDropdownSelectItem } from "@telekom/scale-components-react"
import { ScaleDropdownSelectCustomEvent } from "@telekom/scale-components";
import { AdminFormChild, AdminFormChildParams } from "./common/AdminForm";
import { CreateCustomer, CreateCustomerType, Customer, CustomerType, statusValues } from "../../../api/backend/admin/customerApi";
import { useAdminListContext } from "./common/list/AdminListContext";
import { useEffect } from "react";

const CustomerForm = (): AdminFormChild<Customer & CreateCustomer> =>
    function _({formValues, handleChange, renderTextField, isCreateMode}: AdminFormChildParams<Customer & CreateCustomer>)
        {
            const { setZodType } = useAdminListContext();
            useEffect(() => {
                if (isCreateMode) {
                    setZodType(CreateCustomerType)
                } else {
                    setZodType(CustomerType)
                }
            }, [isCreateMode]);

            return (
                    <>
                        { isCreateMode ? <div>Kundenstammdaten</div> : ""}
                        { renderTextField("Kundename*", "name") }
                        { renderTextField("Kundennummer*", "number", !isCreateMode) }
                        { renderTextField("Rahmenvertragsnummer", "rvid") }
                        { isCreateMode ?
                            <>
                                <hr />
                                <div>Kunden Admin</div>
                                { renderTextField("Vorname*", "userFirstName") }
                                { renderTextField("Nachname*", "userLastName") }
                                { renderTextField("Email*", "userEmail") }
                            </> :
                            <>
                                <ScaleDropdownSelect
                                    label="Status*"
                                    style={{width: "100%", marginTop: 3}}
                                    onScale-change={(event: ScaleDropdownSelectCustomEvent<unknown>) => handleChange(event, "status")}
                                    value={formValues.status}
                                    data-testid="formDropdownStatus"
                                >
                                    {
                                        statusValues.map(s =>
                                            <ScaleDropdownSelectItem key={s} value={s}>
                                                {s}
                                            </ScaleDropdownSelectItem>)
                                    }
                                </ScaleDropdownSelect>
                            </>
                        }
                    </>
            )
        }

export default CustomerForm