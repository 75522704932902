import { ApplicationState } from '../../store/ApplicationSlice';
import DashboardPage from '../dashboard/DashboardPage';
import BookingConfigurationPage from './BookingConfigurationPage';
import CustomerPage from './CustomerPage';

const AdminArea = ({applicationState}: { applicationState: ApplicationState }) => {
  
    return (
        <>
            <DashboardPage dashboardType='ORDERS_TAB' applicationState={applicationState} />
            <CustomerPage applicationState={applicationState} />
            <BookingConfigurationPage applicationState={applicationState} />
        </>
    )
}

export default AdminArea