import classes from "./ProductSelectionPage.module.css";
import ProductCard from "../../../components/product/ProductCard";
import {ApplicationState} from "../../../store/ApplicationSlice";
import { RootState, useAppSelector } from "../../../store/ReduxStore";
import { useSelector } from "react-redux";
import { isDisabledSelector } from "../../../store/UserInfoSlice";

const ProductSelectionPage  = ({applicationState}: { applicationState: ApplicationState }) => {
    const showProductSelectionPage = applicationState === "PRODUCT_SELECTION_SCREEN";
    const {productList} = useAppSelector((state) => state.productReducer);
    const disabled = useSelector<RootState, boolean>(isDisabledSelector);

    return  showProductSelectionPage ?
        <>
            <div className={`${classes.container}`}>
                <div className={`${classes.label}`}>Verbindungen mit 5G<br/> Live Video Broadcast</div>

                <div className={`${classes.textContainer}`}>
                    <div className={`teleNeo32Bold ${classes.biggerText}`}>Unsere beliebtesten Pakete</div>
                    <div className={`teleNeo16Light ${classes.smallerText}`}>Unsere beliebtesten Pakete bieten einen guten vorab konfigurierten Einstiegspunkt in <br/>unsere Services. Individuelle Anpassungen sind möglich.</div>
                </div>
                <div className={`${classes.cardSectionContainer}`}>
                    <div className={`${classes.cardsContainer}`}>
                        {
                            productList.map((prod, index) =>
                                <ProductCard key={index} product={prod}
                                                     nameCard={"Video"}
                                                     infoPoints={[
                                                         "Nutzung des Dienstes nur mit einer SIM-Karte [je Event] möglich",
                                                         "angestrebte Mindest-Upload-Rate beträgt 8 Mbit/s",
                                                         "angestrebte Mindest-Download-Rate beträgt 3 Mbit/s außerhalb von Gebäuden"
                                                     ]}
                                                     disabled={disabled}
                                                     index={index}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </> : null
}

export default ProductSelectionPage