import { MapboxRequest } from "../../api/backend/tmapApi";

export interface ProductDTO {
  id?: string;
  productSpecificationId?: string;
  name?: string;
  price?: number;
  icon?: ProductIcon;
  activationLeadTime?: ProductCharacteristic;
  maxDuration?: ProductCharacteristic;
  lengthBillingUnit?: ProductCharacteristic;
}

export type ProductIcon = "CAMERA" | "PHONE";

export interface ProductCharacteristic {
  name: string;
  value: string;
  unitOfMeasure?: string;
}

export interface SimCardDTO {
  name: string;
  msisdn: string;
  iccid: string;
  imsi: string;
}

export interface CustomerDTO {
  number: string;
  name: string;
}

export type Point = {
  lat: number;
  lng: number;
};

export type Address = {
  street: string;
  zipCode: string;
  city: string;
};

export interface DateOrder {
  dateFrom: string;
  dateTo: string;
  hourFrom?: number;
  hourTo?: number;
  minuteFrom?: number;
  minuteTo?: number;
  zoneId?: string;
}

export interface Order {
  selectedProduct?: ProductDTO;
  position?: MapboxRequest;
  dateOrder?: DateOrder;
  simCard?: SimCardDTO;
  description?: string;
  customerNr?: string;
}

export interface OrderResponse {
  orderId: string;
  status: string;
  productIds: string[];
}

export interface AvailabilityCheckRequest {
  dateOrder?: DateOrder;
  imsi?: string;
}

export interface AvailabilityCheckResponse {
  imsiAvailable: boolean;
}

export interface UserOrder {
  id: string;
  name: string;
  customerName?: string;
  customerNr?: string;
  noOrder: string;
  date?: string;
  duration?: string;
  address?: Address;
  imsi?: string;
  iccid?: string;
  msisdn?: string;
  position?: Point;
  radius?: number;
  status?: string;
  description: string;
  currentUserCancellationDeadline?: string;
}

export interface IsPointAtLayer {
  isAtLayer: boolean;
}

export interface TokenClaims {
  exp: number;
  iat: number;
}

export const pick = <T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> => {
  const result = {} as Pick<T, K>;
  for (const key of keys) result[key] = obj[key];
  return result;
};
