import HeaderLine from "./HeaderLine";
import { useAuth } from "react-oidc-context";
import HorizontalBar from "../common/HorizontalBar";
import classes from "./Header.module.css";
import {
  ApplicationState,
  isOrderingAreaState,
} from "../../../store/ApplicationSlice";
import { Tabs } from "./Tabs";
import { DropdownMenu } from "./DropdownMenu";
import "react-toastify/dist/ReactToastify.css";

const Header = ({
  applicationState,
}: {
  applicationState: ApplicationState;
}) => {
  const auth = useAuth();
  const isAdminArea = !isOrderingAreaState(applicationState);

  return (
    <>
      <HeaderLine className={`${classes.topHeader}`}>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/T_logo_claim_rgb_n.svg`}
            alt="Telekom logo"
            style={{ width: "138px" }}
          />
        </div>
        <div className="teleNeo24Bold">
          5G Live Video {getHeaderText(isAdminArea)}
        </div>
      </HeaderLine>
      <header
        style={{ background: "var(--telekom-color-primary-standard)" }}
        className="second-header"
      >
        <HorizontalBar
          style={{
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: "white",
            height: 60,
          }}
        >
          <Tabs
            applicationState={applicationState}
            isAuthenticated={auth.isAuthenticated}
          />
          {auth.isAuthenticated && (
            <DropdownMenu
              isAdminArea={isAdminArea}
              profileName={auth.user?.profile.name}
            />
          )}
        </HorizontalBar>
      </header>
    </>
  );
};

export default Header;

const getHeaderText = (isAdminArea: boolean): string =>
  isAdminArea ? "Admin Portal" : "Production";
