import { useMemo, useState } from "react"
import { ApplicationState, isOrderingAreaState, isTabPresented, setApplicationState } from "../../../store/ApplicationSlice"
import { Action, CombinedState, ThunkDispatch } from "@reduxjs/toolkit"
import { AppDispatch, RootState, useAppSelector } from "../../../store/ReduxStore"
import { goToBookingWorkflow, goToCustomerDashboard, goToDashboard } from "../../../store/ApplicationSliceActions"
import { useDispatch } from "react-redux"
import classes from "./Header.module.css"
import { Role } from "../../../store/UserInfoSlice"

export const Tabs = ({ applicationState, isAuthenticated }: { applicationState: ApplicationState, isAuthenticated: boolean }) => {
    const isAdminArea = !isOrderingAreaState(applicationState)
    const role: Role | undefined = useAppSelector((state) => state.userInfoSliceReducer.role);
    const tabs = useMemo(() => getTabs(isAdminArea, role), [isAdminArea, role])
    const dispatch = useDispatch<AppDispatch>();
    const [hoveredTab, setHoveredTab] = useState<ApplicationState | undefined>();

    const clickAction = (isAuthenticated: boolean, tab: Tab) => {
        setHoveredTab(undefined)
        if (isAuthenticated) {
            if (tab.action !== undefined) {
                tab.action(dispatch)
            } else if (tab.applicationState !== undefined) {
                dispatch(setApplicationState(tab.applicationState))
            }
        }
    }

    return (
        <div className={`${classes.tabs}`} onMouseLeave={() => setHoveredTab(undefined)}>
            {
                isAuthenticated ?
                    tabs.map(tab => 
                        <div className={`title-text${isSelected(applicationState, tab) ? " selected" : ""}${isHovered(hoveredTab, tab) ? " hovered" : ""}`} key={tab.tabName}>
                            <h5 onClick={() => clickAction(isAuthenticated, tab)} onMouseOver={() => setHoveredTab(tab.applicationState)}>
                                {tab.tabName}
                            </h5>
                            {
                                tab.submenu !== undefined ?
                                <div className={`${classes.submenu}`}>
                                    <div>
                                        {
                                            tab.submenu.map(submenu => 
                                                <div className={`${classes.menuElement}`} key={submenu.tabName} onClick={() => clickAction(isAuthenticated, submenu)}>{submenu.tabName}</div>
                                            )
                                        }
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    )
                    : null
            }
        </div>
    )
}

const isHovered = (hoveredTab: ApplicationState | undefined, tab: Tab) => hoveredTab === tab.applicationState

const isSelected = (applicationState: ApplicationState, tab: Tab) => applicationState === tab.applicationState || tab.submenu?.some(subelement => applicationState === subelement.applicationState)

type Tab = {
    tabName: string;
    applicationState?: ApplicationState;
    action?: (dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => void,
    submenu?: Tab[]
}

const getTabs = (isAdminArea: boolean, role?: Role): Tab[] => {
    const tabs: Tab[] = isAdminArea ? [{
            tabName: "SIM Karten",
            applicationState: "SIMCARDS_TAB"
        }, {
            tabName: "Bestellungen",
            applicationState: "ORDERS_TAB",
            action: (dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => dispatch(goToCustomerDashboard())
        }, {
            tabName: "Kundenprofil",
            applicationState: "CUSTOMERS_TAB",
            submenu: [
                {
                    tabName: "Nutzerverwaltung",
                    applicationState: "CUSTOMERS_USER_TAB"
                },
                {
                    tabName: "Stammdaten",
                    applicationState: "CUSTOMERS_TAB"
                }
            ]
        }, {
            tabName: "Buchungskonfiguration",
            applicationState: "CONFIGURATION_TAB",
            submenu: [
                {
                    tabName: "Rollenabhängig",
                    applicationState: "CONFIGURATION_TAB",
                },
                {
                    tabName: "Kundenabhängig",
                    applicationState: "CUSTOMER_CONFIGURATION_TAB"
                }
            ]
        }] : [{
            tabName: "Live Video Production",
            applicationState: "PRODUCT_SELECTION_SCREEN",
            action: (dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => dispatch(goToBookingWorkflow())
        }, {
            tabName: "Dashboard",
            applicationState: "DASHBOARD_SCREEN",
            action: (dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => dispatch(goToDashboard())
        }, {
            tabName: "Weitere Infos",
            action: () => window.open("https://developer.telekom.com/de/produkte/5G-Live-Video-Production", "_blank")
        }
    ]

    return tabs.filter(tab => tab.applicationState === undefined || isTabPresented(role, tab.applicationState))
}