import { combineReducers, configureStore, createSelector } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import applicationState from './ApplicationSlice'
import mapReducer from './workflow/MapSlice'
import dateReducer from './workflow/DateSlice'
import userOrdersReducer from './UserOrdersSlice'
import { isFromDtSelector, userInfoSliceReducer } from './UserInfoSlice'
import productReducer, { getSelectedProductSelector } from './workflow/ProductSlice'
import simCardsReducer, { getSelectedSimCard } from "./workflow/SimCardsSlice";
import { AvailabilityCheckRequest, Order } from './workflow/models';
import {notificationReducer} from "./NotificationSlice";

const combinedReducer = combineReducers({
  mapReducer, applicationState, dateReducer, productReducer, simCardsReducer, userOrdersReducer, notificationReducer, userInfoSliceReducer
})

export const store = configureStore({
  reducer: combinedReducer
})

export const getOrderSelector: (state: RootState) => Order = createSelector(
    [(rootState: RootState) => rootState, getSelectedProductSelector, getSelectedSimCard, isFromDtSelector],
    (rootState, selectedProduct, selectedSimCard, isFromDt) => {
        const customerNr = isFromDt ? rootState.simCardsReducer.selectedRelatedPartyId : rootState.userInfoSliceReducer.customerNr
        return {
          selectedProduct: selectedProduct,
          position: rootState.mapReducer.mapboxRequest,
          dateOrder: rootState.dateReducer,
          simCard: selectedSimCard,
          description: rootState.productReducer.userProductName,
          customerNr
        };
    }
)

export const getAvailabilitySelector: (state: RootState) => AvailabilityCheckRequest = createSelector(
    [(rootState: RootState) => rootState, getSelectedSimCard],
    (rootState, selectedSimCard) => {
        return {
          dateOrder: rootState.dateReducer,
          imsi: selectedSimCard?.imsi,
          selectedPlace: rootState.mapReducer.mapboxRequest
        };
    }
)

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch