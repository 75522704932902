import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { getOrders } from "api/backend/userOrdersApi";
import { UserOrder } from "./workflow/models";
import { setUserOrdersList } from "./UserOrdersSlice";
import { ApplicationState, setApplicationState } from "./ApplicationSlice";
import { RESET_WORKFLOW } from "./CommonActions";
import { getProductList } from "api/backend/productApi";
import { setProductList } from "./workflow/ProductSlice";
import { getCustomers } from "api/backend/simCardsApi";
import { setSimCardsListRelatedParties } from "./workflow/SimCardsSlice";
import { store } from "./ReduxStore";

const dashboardThunk = async (
  applicationState: ApplicationState,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  let userOrders = [] as UserOrder[];
  try {
    userOrders = (await getOrders()).data;
  } catch (error) {
    console.error("Error in reading orders");
  }
  dispatch(setUserOrdersList(userOrders));
  dispatch(setApplicationState(applicationState));
};

export const goToDashboard = createAsyncThunk<void, void>(
  "application/goToDashboard",
  async (_, { dispatch }) => dashboardThunk("DASHBOARD_SCREEN", dispatch)
);

export const goToCustomerDashboard = createAsyncThunk<void, void>(
  "application/goToCustomerDashboard",
  async (_, { dispatch }) => dashboardThunk("ORDERS_TAB", dispatch)
);

export const goToBookingWorkflow = createAsyncThunk<void, void>(
  "application/goToBookingWorkflow",
  async (_, { dispatch }) => {
    dispatch({ type: RESET_WORKFLOW });
    await reloadProductsAndCustomers();
  }
);

export const reloadProductsAndCustomers = async () => {
  await getProductAction();
  await getSimCardsRelatedPartiesAction();
};

const getProductAction = async () => {
  try {
    const products = await getProductList();
    store.dispatch(setProductList(products));
  } catch (e) {
    console.error("Error in reading products");
  }
};

const getSimCardsRelatedPartiesAction = async () => {
  try {
    const customers = await getCustomers();
    store.dispatch(setSimCardsListRelatedParties(customers));
  } catch (e) {
    console.error("Error in reading simcards");
  }
};
