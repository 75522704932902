import { ScaleButton, ScaleDropdownSelect, ScaleDropdownSelectItem, ScaleIconActionEdit, ScaleIconActionRemove, ScalePagination, ScaleTable } from '@telekom/scale-components-react';
import classes from './AdminList.module.css';
import { ScaleDropdownSelectCustomEvent, ScalePaginationCustomEvent } from '@telekom/scale-components';
import { AdminComponentContent, AdminListRow } from './AdminList';
import { useAdminListContext } from './AdminListContext';
import { PaginationEventDirection } from '@telekom/scale-components/dist/types/components/pagination/pagination';
import { Role } from '../../../../../store/UserInfoSlice';

const pageSizes = [5, 10, 20, 50]
export type ShowEditControlsFunction = (role?: Role) => boolean
export const onlyDtEditorRoles: ShowEditControlsFunction = (role?: Role) => role === "DTADMIN" || role === "DTSERVICEAGENT"
export type Predicate<FORM> = (row: FORM) => boolean;
type EditControls<FORM> = {
    isVisible: boolean;
    deleteButtonDisabled?: Predicate<FORM>;
}

export const FormTable = <FORM,ID = Partial<FORM>>({componentContent, editControls, children}:
    {componentContent: AdminComponentContent<FORM,ID>, editControls: EditControls<FORM>, children: AdminListRow<FORM>,}) => {
    const { sortBy, pageNumber, pageSize, totalElements, rows, formToId, setSortBy, setSelected, setPageNumber, setPageSize, setDeleteId, openNewForm } = useAdminListContext<FORM, ID>()

    const openEditForm = (form: FORM): void => {
        setSelected({
            form,
            isCreateMode: false
        })
    }

    const handlePagination = (event: ScalePaginationCustomEvent<{
        startElement?: number;
        direction: PaginationEventDirection;
    }>) => {
        const direction = event.detail.direction;

        if (direction === "NEXT") {
            setPageNumber((currentPage) => currentPage + 1);
        } else if (direction === "PREVIOUS") {
            setPageNumber((currentPage) => currentPage - 1);
        } else if (direction === "LAST") {
            setPageNumber(getLastPage(totalElements, pageSize));
        } else if (direction === "FIRST") {
            setPageNumber(0);
        }
    }

    return (<>
        <div className={classes.controls}>
            <div>
                <ScaleDropdownSelect
                    label="Sortieren nach"
                    style={{width: "100%"}}
                    value={sortBy}
                    onScale-change={(e: ScaleDropdownSelectCustomEvent<unknown>) => setSortBy(e.target.value)}
                >
                    {
                        componentContent.tableHeaders.map(header =>
                            <ScaleDropdownSelectItem key={String(header.value)} value={header.value} selected={header.value === sortBy}>
                                {header.name}
                            </ScaleDropdownSelectItem>)
                    }
                </ScaleDropdownSelect>
                {
                    editControls.isVisible ? <ScaleButton onClick={openNewForm}>{componentContent.formName} hinzufügen</ScaleButton> : null
                }
            </div>
        </div>
        <div className={classes.table}>
            <ScaleTable>
                <table>
                    <thead>
                    <tr>
                        {
                            componentContent.tableHeaders.map(header => <th key={`table_header_${String(header.value)}`}>{header.name}</th>)
                        }
                        {
                            editControls.isVisible ? <th>Aktion</th> : null
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows?.map((row, ind) => 
                        {
                            const deleteButtonDisabled = editControls.deleteButtonDisabled?.(row) ?? false;
                            return (<tr key={`${ind}`}>
                                    {children(row)}
                                    {
                                        editControls.isVisible ? 
                                            <td>
                                                <div className={classes.actionIcons}>
                                                    <ScaleIconActionEdit size={16} onClick={() => openEditForm(row)}/>
                                                    <ScaleIconActionRemove className={`${deleteButtonDisabled ? classes.disabled : ""}`} size={16} onClick={() => !deleteButtonDisabled && setDeleteId(formToId(row))}/>
                                                </div>
                                            </td>
                                            : null
                                    }
                                </tr>)

                        }
                        )
                    }
                    </tbody>
                </table>
            </ScaleTable>
            <div className={classes.tableControls}>
                <ScaleDropdownSelect
                    label="Zeilen pro Seite"
                    style={{width: 160}}
                    value={`${pageSize}`}
                    onScale-change={(e: ScaleDropdownSelectCustomEvent<unknown>) => {
                        setPageNumber(0)
                        setPageSize(e.target.value)
                    }}
                >
                    {
                        pageSizes.map(_pageSize =>
                            <ScaleDropdownSelectItem
                                key={`pageSize${_pageSize}`}
                                value={_pageSize}
                                selected={_pageSize === pageSize}>
                                    {_pageSize}
                            </ScaleDropdownSelectItem>
                        )
                    }
                </ScaleDropdownSelect>
                <ScalePagination
                    pageSize={pageSize}
                    startElement={pageSize * pageNumber}
                    totalElements={totalElements}
                    ariaLabelFirstPage="Zur ersten Seite"
                    ariaLabelLastPage="Zur nächsten Seite"
                    ariaLabelPreviousPage="Zur vorigen Seite"
                    ariaLabelNextPage="Zur letzten Seite"
                    onScale-pagination={handlePagination}
                ></ScalePagination>
            </div>
        </div>
    </>)
}

export const EmptyTable = <FORM,ID>({componentContent, showEditControls}: {componentContent: AdminComponentContent<FORM,ID>, showEditControls: boolean}) => {
    const { openNewForm } = useAdminListContext()

    return (
        <div style={{marginTop: 40}}>
            <h3 className="teleNeo24Bold">No {componentContent.formName} added</h3>
            {
                showEditControls ?
                <>
                    <div>Click the button below to add the first {componentContent.formName}</div>
                    <div style={{marginTop: 32}}>
                        <ScaleButton onClick={openNewForm}>{componentContent.formName} hinzufügen</ScaleButton>
                    </div>
                </>
                : null
            }
            
        </div>
    )
}

export const getLastPage = (totalElements: number, pageSize: number) => pageSize > 0 ? Math.max(Math.floor((totalElements - 1) / pageSize), 0) : 0