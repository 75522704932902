import { roleValues } from "../../../store/UserInfoSlice";
import { z } from 'zod';
import * as api from "../../api";
import {authHeader} from "../../apiHeader";
import { FormToIdFunction } from "../../api";

const BookingConfigurationType = z.object({
    productOfferingId: z.string(),
    minBookingLeadTime: z.number(),
    maxBookingLeadTime: z.number(),
    cancellationDeadline: z.number(),
    bookingPeriod: z.number(),
});

export const RoleBookingConfigurationType = BookingConfigurationType.extend({
    role: z.enum(roleValues),
  });

export type RoleBookingConfiguration = z.infer<typeof RoleBookingConfigurationType>;
const roleBookingConfigurationsUrl = `${process.env.REACT_APP_BACKEND_API_URL}/roleBookingConfigs`;

export type Id = Pick<RoleBookingConfiguration, "role" | "productOfferingId">

export const formToId:FormToIdFunction<RoleBookingConfiguration, Id> = (roleBookingConfiguration: RoleBookingConfiguration) => ({
    productOfferingId: roleBookingConfiguration.productOfferingId,
    role: roleBookingConfiguration.role
})

export const getRoleBookingConfigurations = (paginationRequest: api.PaginationRequest<RoleBookingConfiguration>): Promise<api.PaginationResponse<RoleBookingConfiguration[]>> =>
    api.authorizedGet<api.PaginationResponse<RoleBookingConfiguration[]>>(api.buildGetUrlWithParameters(roleBookingConfigurationsUrl, paginationRequest), authHeader())

export const postRoleBookingConfiguration = (form: RoleBookingConfiguration) =>
    api.authorizedPostNoBody(roleBookingConfigurationsUrl, authHeader(), form)

export const putRoleBookingConfiguration = (form: RoleBookingConfiguration) =>
    api.authorizedPutNoBody(roleBookingConfigurationsUrl, authHeader(), form)

export const deleteRoleBookingConfiguration = (deleteForm: Id) =>
    api.authorizedDeleteNoBody(roleBookingConfigurationsUrl, authHeader(), deleteForm)