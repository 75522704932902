import classes from "./LoginPage.module.css"
import { useAuth } from "react-oidc-context";

const LoginPage = () => {
    const auth = useAuth();
    
    const handleButtonClick = async () => {
        await auth.signinRedirect()
    };

    return (
        <div className={`row ${classes.loginContainer}`}>
            <div className="col"></div>
            <div className={`col ${classes.loginPanel}`}>
                <div className={`${classes.title}`}>5G Live Video Production</div>
                <div className={`${classes.header}`}>Bitte loggen Sie sich ein</div>

                <div style={{marginBottom: 27, height: 26}}>
                    <a className={`${classes.smallText} float-end link`} style={{marginTop: "0.25em"}} href="https://passwort.telekom.de">
                        Passwort vergessen?
                    </a>
                </div>
                <button type="submit" className="btn telekom-button-wide" onClick={handleButtonClick}>Login</button>
            </div>
            <div className="col"></div>
        </div>
    )
}

export default LoginPage