import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Provider } from "react-redux";
import { store } from "store/ReduxStore";
import "@telekom/scale-components/dist/scale-components/scale-components.css";
import "./i18n";
import {
  applyPolyfills,
  defineCustomElements,
} from "@telekom/scale-components/loader";
import Notification from "components/ui/common/message/Notification";
import App from "./App";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import {
  getOIDCAuthority,
  getOIDCClientId,
  getOIDCRedirectUrl,
} from "utils/env";
import { getUserInfo } from "api/backend/applicationApi";
import { setUserInfo } from "store/UserInfoSlice";
import { getCustomerData, Status } from "api/backend/admin/customerApi";
import { reloadProductsAndCustomers } from "store/ApplicationSliceActions";

const el = document.getElementById("root");
if (el === null) throw new Error("Root container missing in index.html");

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const oidcConfig: AuthProviderProps = {
  authority: getOIDCAuthority(),
  client_id: getOIDCClientId(),
  redirect_uri: getOIDCRedirectUrl(),
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  iframeScriptOrigin: "window.location.origin",
  onSigninCallback: async () => {
    const userInfo = await getUserInfo();
    let status: Status | undefined = undefined;
    if (userInfo.customer?.number) {
      status = (await getCustomerData(userInfo.customer?.number)).status;
    }

    store.dispatch(
      setUserInfo({
        ...userInfo,
        status,
      })
    );
    reloadProductsAndCustomers();
  },
};

const root = ReactDOM.createRoot(el);
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <App />
        <Notification />
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
