import { ApplicationState } from '../../../store/ApplicationSlice';
import { Id, deleteRoleBookingConfiguration, getRoleBookingConfigurations, postRoleBookingConfiguration, putRoleBookingConfiguration, RoleBookingConfigurationType, formToId } from '../../../api/backend/admin/configApi';
import RoleConfigurationForm from './RoleConfigurationForm';
import { useAppSelector } from '../../../store/ReduxStore';
import { AdminListWrapper } from './common/list/AdminListWrapper';
import { onlyDtEditorRoles } from './common/list/FormTable';


const RoleConfigurationListComponent = ({applicationState}: { applicationState: ApplicationState }) => {
    const {productList} = useAppSelector((state) => state.productReducer);
    const productMap = productList.reduce((acc, current) => {
        if (current.id !== undefined) {
            acc.set(current.id, current.name);
        }
        return acc;
      }, new Map());
  
    return (
        <AdminListWrapper
            componentContent={{
                tableName: 'Rollenabhängige Grundkonfiguration für Buchungen',
                formName: 'Konfiguration',
                getIdentifier: (id: Id) => `${id.role}, ${productMap.get(id.productOfferingId)}`,
                tableHeaders: [{
                        name: 'Role',
                        value: 'role'
                    }, {
                        name: 'Produkt',
                        value: 'productOfferingId'
                    }, {
                        name: 'min. leadtime in minutes',
                        value: 'minBookingLeadTime'
                    }, {
                        name: 'max. leadtime in days',
                        value: 'maxBookingLeadTime'
                    }, {
                        name: 'cancellation deadling in minutes',
                        value: 'cancellationDeadline'
                    }, {
                        name: 'max booking period in hours',
                        value: 'bookingPeriod'
                    }
                ]
            }}
            restOperations={{
                getList: getRoleBookingConfigurations,
                createElement: postRoleBookingConfiguration,
                modifyElement: putRoleBookingConfiguration,
                deleteElement: deleteRoleBookingConfiguration
            }}
            visibilityConfiguration={{
                isVisible: applicationState === "CONFIGURATION_TAB",
                isShowEditControls: onlyDtEditorRoles,
            }}
            zodType={RoleBookingConfigurationType}
            formToId={formToId}
            childForm={RoleConfigurationForm(productList)}
        >
            {
                (row) => (
                    <>
                        <td>{row.role}</td>
                        <td>{productMap.get(row.productOfferingId)}</td>
                        <td>{row.minBookingLeadTime}</td>
                        <td>{row.maxBookingLeadTime}</td>
                        <td>{row.cancellationDeadline}</td>
                        <td>{row.bookingPeriod}</td>
                    </>
                )
            }
        </AdminListWrapper>
    )
}

export default RoleConfigurationListComponent