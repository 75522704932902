/* eslint-disable @typescript-eslint/no-explicit-any */

import { toast } from "react-toastify";
import i18n from "../i18n";

export type HTTP_METHOD = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export type PaginationRequest<T> = {
  page?: string;
  size?: string;
  sortBy?: keyof T;
  sortDirection?: "ASC" | "DESC";
};

export type ResponseErrorBody = {
  params?: ValidationParams;
  errorCode?: string;
  reason: string;
  traceId: string;
  userTraceId: string;
};
export type PaginationResponse<T> = {
  content: T;
  totalElements: number;
};
export interface RestError {
  status: number;
  body?: ResponseErrorBody;
}

export type ValidationParams = Record<string, unknown>;

//Function which extracts id from form
export type FormToIdFunction<FORM, ID> = (form: FORM) => ID;

const fetchPromise = async (
  url: string,
  method: HTTP_METHOD,
  header: Headers,
  body: unknown | null = null,
  responseMapper?: (res: Response) => Promise<any>
) => {
  try {
    const res = await fetch(url, {
      method: method,
      headers: header,
      body: body !== null ? JSON.stringify(body) : null,
    });

    if (!res.ok) {
      const text = await res.text();

      throw {
        status: res.status,
        body: text.length !== 0 ? JSON.parse(text) : undefined,
      };
    }

    if (res.status !== 204) {
      return responseMapper ? responseMapper(res) : res.json();
    } else {
      return null;
    }
  } catch (e) {
    console.error(`fetchPromise error: ${JSON.stringify(e)}`);

    const responseError: RestError = e as RestError;
    const errorMessage = getToastMessage(responseError);
    if (errorMessage) {
      displayToast(errorMessage);
    } else {
      throw responseError;
    }
  }
};

const displayToast = (errorMessage: string) => {
  toast.error(errorMessage, {
    autoClose: false,
    theme: "colored",
  });
};

export const getToastMessage = (
  responseError: RestError
): string | undefined => {
  const { body, status } = responseError;
  if (!body) {
    return `Error code: ${status}`;
  }

  if (body.errorCode !== undefined) {
    return;
  } else {
    const { reason, userTraceId } = body;
    return i18n.t("error.default", { reason, userTraceId }) ?? "";
  }
};

export const buildGetUrlWithParameters = <T,>(
  url: string,
  paginationRequest: PaginationRequest<keyof T>
): string => {
  const { sortBy, sortDirection, ...request } = paginationRequest;
  const queryString = new URLSearchParams({
    ...request,
    sort: `${sortBy},${sortDirection}`,
  }).toString();
  return `${url}${queryString ? `?${queryString}` : ""}`;
};

const authorizedMethod = <T,>(
  method: HTTP_METHOD,
  url: string,
  authToken: string,
  body: unknown | null,
  responseMapper?: (res: Response) => Promise<T>
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.set("Authorization", authToken);
  headers.append("Access-Control-Allow-Origin", "*");

  return fetchPromise(url, method, headers, body, responseMapper);
};

const authorizedMethodNoBody = (
  method: HTTP_METHOD,
  url: string,
  authToken: string,
  body: unknown | null
) =>
  authorizedMethod<number>(
    method,
    url,
    authToken,
    body,
    async (res) => new Promise((resolve) => resolve(res.status))
  );

export const authorizedPost = <T,>(
  url: string,
  authToken: string,
  body: unknown | null,
  responseMapper?: (res: Response) => Promise<T>
) => authorizedMethod("POST", url, authToken, body, responseMapper);

export const authorizedPostNoBody = <T,>(
  url: string,
  authToken: string,
  body: T | null
) => authorizedMethodNoBody("POST", url, authToken, body);

export const authorizedPutNoBody = <T,>(
  url: string,
  authToken: string,
  body: T | null
) => authorizedMethodNoBody("PUT", url, authToken, body);

export const authorizedDeleteNoBody = <ID,>(
  url: string,
  authToken: string,
  body: ID | null
) => authorizedMethodNoBody("DELETE", url, authToken, body);

export const authorizedGet = <T,>(
  url: string,
  authToken: string,
  responseMapper?: (res: Response) => Promise<T>
) => authorizedMethod("GET", url, authToken, null, responseMapper);
